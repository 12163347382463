<template>
    <div class="pages-container">
        <h1>Résumé (4/4)</h1>

        <div class="btn-frame">
            <div class="download-btn" @click="downloadDoc">Télécharger la recommandation</div>
        </div>

        <loader-spin v-if="isLoading"></loader-spin>

        <table v-else class="actions-table">
            <tr>
                <th>Action proposée</th>
                <th>Code</th>
                <th>Liquidités</th>
            </tr>

            <tr v-for="(action, index) in actionList" :key="index">
                <td>{{ $store.getters.codeTranslations[action.code] }}</td>
                <td>{{ action.code }}</td>
                <td>{{ toEuro(action.liquidities) }}</td>
            </tr>
        </table>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            actionList: [],
            isLoading: false,
        }
    },
    methods: {
        async loadActions() {
            this.isLoading = true
            const recommendationRes = await axios.get(this.$store.getters.get_api_url + 'clients//find-recommendation/' + this.$route.params.recommendationId, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })
            const recommendation = recommendationRes.data
            
            if (recommendation.selectedScenario) {
                const res = await axios.get(this.$store.getters.get_api_url + 'referential/actions/' + recommendation.selectedScenario, {
                    headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                })

                this.actionList = res.data
            }
            this.isLoading = false

        },
        async downloadDoc() {
            this.isLoading = true

            try {
                const res = await axios.post(this.$store.getters.get_api_url + 'services/recommendation', {
                    clientId: this.$route.params.clientId,
                    recommendationId: this.$route.params.recommendationId,
                }, {
                    headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                })

                if (res.status == 200) {
                    const doc = await axios.get(this.$store.getters.get_api_url + 'documents/recommendation/Recommandation-' + this.$route.params.clientId + '.docx', { 
                        responseType: 'blob',
                        headers: {
                            authorization : 'Bearer ' + this.$store.getters.get_token
                        }
                    })

                    const blob = new Blob([doc.data], { type: 'application/docx' })
                    const link = document.createElement('a')
                    link.href = URL.createObjectURL(blob)
                    link.download = 'Recommandation.docx'
                    link.click()
                    URL.revokeObjectURL(link.href)
    
                }
                
                this.isLoading = false
            } catch (err) {
                console.log(err)
                this.isLoading = false
                alert('Erreur lors de la création du bilan automatisé')
            }

        },
    },
    mounted() {
        this.loadActions()
    },
}
</script>

<style scoped>
.pages-container {
    padding: 20px;
}
h1 {
    font-size: 40px;
    color: rgb(0, 0, 76);
    display: inline-block;
    border-bottom: 1px solid lightgrey;
}

.actions-table {
    width: 80%;
    margin: auto;
    background-color: white;
    border-spacing: 0px;
    border-collapse: collapse;
}
.actions-table th, td {
    border: 1px solid rgb(243, 245, 247);
    padding: 10px;
}

.actions-table th {
    background-color: rgb(3, 3, 91);
    color: white;
}

.btn-frame {
    margin: 20px;
    display: flex;
    justify-content: center;
}

.btn-frame label, .btn-frame select {
    font-size: 18px;
}

.download-btn {
    cursor: pointer;
    background-color: rgb(255, 140, 0);
    display: inline-block;
    padding: 15px;
    border-radius: 10px;
    font-weight: bold;
    color: white;
}
.download-btn:hover {
    background-color: rgba(255, 140, 0, 0.737);
}
</style>