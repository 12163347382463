<template>
    <div>
        <h3>Simulations de retraite</h3>

        <div class="simulation-container">
            <div class="simulation" v-for="simulation in simulations" :key="simulation._id">
                <h4>Simulation de retraite de {{ simulation.person.firstname }} {{ simulation.person.lastname }}</h4>
                <h6>Situation au {{ simulation.idmagnaresult.entete.dt_ref_droits_acquis }}</h6>

                <div class="synthese-retraite">
                    <h2>Synthèse des résultats</h2>
                    <div class="details">
                        <p><strong>Nom :</strong> {{ simulation.person.firstname }} {{ simulation.person.lastname }}</p>
                        <p><strong>Date de départ à taux plein :</strong> {{ simulation.idmagnaresult?.entete?.dt_depart_tx_plein }}</p>
                        <p><strong>Pension nette annuelle :</strong> {{  }} €</p>
                        <p><strong>Total trimestres requis :</strong> {{ simulation.idmagnaresult?.entete?.nb_trim_tx_plein_age }}</p>
                    </div>
                </div>

                <TrimestresChart
                    :totalTrimestresRequis="simulation?.idmagnaresult?.entete?.nb_trim_tx_plein_age"
                    :totalTrimestresActuels="simulation?.idmagnaresult?.entete?.nb_trim_tous_regimes?.trim"
                />
                {{ simulation }}
            </div>

            
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import TrimestresChart from "./TrimestresChart.vue";

export default {
    data() {
        return {
            simulations: []
        }
    },
    components: {
        TrimestresChart,
    },
    methods: {
        async loadDatas() {
            const userRes = await axios.get(this.$store.getters.get_api_url + 'clients/career/' + this.$store.getters.userId, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            if (userRes.data) {
                this.simulations.push({
                    ...userRes.data,
                    person: this.$store.getters.getPersons.find(p => p.role == 'user')
                })
            }

            const spouseRes = await axios.get(this.$store.getters.get_api_url + 'clients/career/' + this.$store.getters.spouseId, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            if (spouseRes.data) {
                this.simulations.push({
                    ...spouseRes.data,
                    person: this.$store.getters.getPersons.find(p => p.role == 'spouse')
                })
            }
        }
    },
    mounted() {
        this.loadDatas()
    }
}
</script>

<style scoped>
.simulation-container {
    display: flex;
}

.simulation {
    flex-basis: 100%;
    margin: 10px;
}

.synthese-retraite {
  background: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.details p {
  margin: 5px 0;
  font-size: 16px;
}
</style>