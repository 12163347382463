<template>
    <form>
        <div class="form-section">
            <label for="owner">Actif à céder</label>
            <select id="owner" v-model="asset_id">
                <option v-for="asset in assetList" :key="asset._id" :value="asset._id">{{ asset.label || $store.getters.propertyTypesList[asset.type] }} - {{ toEuro(asset.value) }}</option>
            </select>
        </div>

        <div class="btn-submit" @click="submit">Enregistrer</div>
        <p class="errorMsg">{{ errorMsg }}</p>
    </form>
</template>

<script>
export default {
    props: ['action'],
    data() {
        return {
            asset_id: undefined,
            errorMsg: "",
        }
    },
    methods: {
        async property_delete() {
            if (!this.asset_id) {
                this.errorMsg = "Choisissez un actif à céder"

            } else {
                const property = this.$store.getters.getAllProperties.find(p => p._id == this.asset_id)
                await this.$store.dispatch('sell_property', property)
                this.$emit('closeModale')
            }
        },
        submit() {
            this.property_delete()
        }
    },
    computed: {
        assetList() {
            const assetOwned = this.$store.getters.getAllProperties.filter(a => a.detention.map(d => d.owner_id).includes(this.$store.getters.userId) || a.detention.map(d => d.owner_id).includes(this.$store.getters.spouseId))
            if (this.action == 'sell_main_property') {
                return assetOwned.filter(p => p.type == 'main_property')

            } else if (this.action == 'location_sell') {
                return assetOwned.filter(p => p.type == 'location')

            } else if (this.action == 'scpi_sell') {
                return assetOwned.filter(p => p.type == 'scpi')

            } else {
                return []
            }
        }
    }
}
</script>

<style src="./form_styles.css" scoped></style>