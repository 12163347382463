<template>
    <input
        ref="inputRef"
        type="text"
    />
</template>
  
<script>
    import { watch } from 'vue'
    import { useCurrencyInput } from 'vue-currency-input'
    
    export default {
        name: 'CurrencyInput',
        props: {
            modelValue: Number,
        },
        setup(props) {
            const { inputRef, setValue } = useCurrencyInput({ currency: 'EUR' })

            watch(
                () => props.modelValue,
                (value) => {
                    setValue(value)
                }
            )
    
            return { inputRef }
        }
    }
</script>