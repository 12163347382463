<template>
    <h1 class="section-label">Simulations des emprunts</h1>
        <div class="separator"></div>

        <table class="simulation-table">
            <tr>
                <th>Type d'emprunt</th>
                <th>Capital</th>
                <th>Durée</th>
                <th>Taux d'intérêts</th>
                <th>Détail</th>
            </tr>

            <tr v-for="debt in newDebts" :key="debt._id">
                <td>{{ $store.getters.debtTypes[debt.type] }}</td>
                <td>{{ toEuro(debt.value) }}</td>
                <td>{{ Math.abs((new Date(debt.end_date).getFullYear() - new Date(debt.start_date).getFullYear()) * 12 + new Date(debt.end_date).getMonth() - new Date(debt.start_date).getMonth()) }} mois</td>
                <td>{{ debt.rate }} %</td>
                <td @click="toggleShowLoan(debt._id)"> 
                    <edit-icon style="cursor: pointer"/>

                    <small-modale :show="showLoan == debt._id" @toggle="toggleShowLoan(debt._id)">
                        <table class="loan-data-table">
                            <tr>
                                <th>Coût des intérêts</th>
                                <td>{{ toEuro(currentLoan.interest_cost) }}</td>
                            </tr>

                            <tr>
                                <th>Coût de l'assurance</th>
                                <td>{{ toEuro(currentLoan.insurance_cost) }}</td>
                            </tr>

                            <tr>
                                <th>Coût total de l'emprunt</th>
                                <td>{{ toEuro(currentLoan.total_loan_cost) }}</td>
                            </tr>
                        </table>

                        <table class="amortization-table">
                            <tr>
                                <th>Mois</th>
                                <th>Capital restant dû (début)</th>
                                <th>Mensualité</th>
                                <th>Intérêts</th>
                                <th>Assurance</th>
                                <th>Amortissement</th>
                                <th>Capital restant dû (fin)</th>
                            </tr>

                            <tr v-for="(month, index) in currentLoan.amortization_table" :key="index">
                                <td>{{ index + 1 }}</td>
                                <td>{{ toEuro(month.crd_before) }}</td>
                                <td>{{ toEuro(month.monthly_payment) }}</td>
                                <td>{{ toEuro(month.interest) }}</td>
                                <td>{{ toEuro(month.insurance) }}</td>
                                <td>{{ toEuro(month.amortization) }}</td>
                                <td>{{ toEuro(month.crd_after) }}</td>
                            </tr>
                        </table>
                    </small-modale>
                </td>
            </tr>
        </table>
    
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            showLoan: undefined,
            currentLoan: {},
        }
    },
    methods: {
        async loadDebtComputation(debt) {

            const body = {
                _id: debt._id,
                refund_type: debt.refund_type,
                value: debt.value,
                rate: debt.rate / 100,
                start_date: debt.start_date,
                end_date: debt.end_date,
                insurance_rate: 0
            }

            const res = await axios.post(this.$store.getters.get_api_url + 'simulators/loan-simulation', body, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            this.currentLoan = res.data
        },
        toggleShowLoan(_id) {
            if (this.showLoan == _id) {
                this.showLoan = undefined
            } else {
                this.showLoan = _id
            }   
        },
    },
    watch: {
        showLoan() {
            if (this.showLoan) {
                const selectedLoan = this.newDebts.find(d => d._id == this.showLoan)
                this.currentLoan = this.loadDebtComputation(selectedLoan)
            }
        }
    },
    computed: {
        newDebts() {
            return this.$store.getters.getAllDebts.filter(debt => debt.isIpCreation)
        },
    }
}
</script>

<style src="./style.css" scoped></style>

<style scoped>
.loan-data-table {
    width: 70%;
    border-collapse: collapse;
    margin: 10px auto;
}

.loan-data-table th,
.loan-data-table td {
    font-size: 16px;
    padding: 5px 10px;
    border: 1px solid lightgrey;
}

.loan-data-table th {
    background-color: #0a1c6b;
    color: white;
    text-align: left;
}
.loan-data-table td {
    text-align: center;
}

.amortization-table {
    border-collapse: collapse;
}
.amortization-table th,
.amortization-table td {
    font-size: 14px;
    border: 1px solid lightgrey;
    text-align: center;
    padding: 5px;
}
.amortization-table th {
    background-color: #0a1c6b;
    color: white;
}
</style>