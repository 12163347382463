<template>
    <loader-spin v-if="is_loading"></loader-spin>

    <div class="chart-container" v-else>
        
        <h3>{{ totalTrimestresActuels }} trimestres enregistrés</h3>
        <p>{{ totalTrimestresRequis - totalTrimestresActuels }} trimestres à obtenir</p>
        <DoughnutChart :chart-data="chartData" :chart-options="chartOptions" />
    </div>
</template>

<script>
import { Doughnut } from "vue-chartjs";
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    ArcElement,
} from "chart.js";

// Enregistre les composants Chart.js
ChartJS.register(Title, Tooltip, Legend, ArcElement);

export default {
    name: "TrimestresChart",
    components: {
        DoughnutChart: Doughnut,
    },
    data() {
        return {
            is_loading: false,
        }
    },
    props: {
        totalTrimestresRequis: {
            type: Number,
            required: true,
        },
        totalTrimestresActuels: {
            type: Number,
            required: true,
        },
    },
    computed: {
        trimestresRestants() {
            return this.totalTrimestresRequis - this.totalTrimestresActuels;
        },
        chartData() {
            return {
                labels: ["Trimestres enregistrés", "Trimestres à obtenir"],
                datasets: [
                {
                    data: [this.totalTrimestresActuels, this.trimestresRestants],
                    backgroundColor: ["#3A506B", "#D4B59E"], // Couleurs personnalisées
                    hoverBackgroundColor: ["#2E4057", "#B49480"],
                },
                ],
            };
        },
        chartOptions() {
            return {
                responsive: true,
                plugins: {
                legend: {
                    display: false, // Cache la légende pour correspondre au style
                },
                },
                cutout: "70%", // Pour une apparence circulaire
            };
        },
    },
};
</script>

<style scoped>
.chart-container {
    text-align: center;
    margin: 20px auto;
    width: 300px;
}
h3 {
    font-size: 18px;
    color: #3a506b;
}
p {
    font-size: 14px;
    color: #666;
}
</style>
  