<template>
    <div class="modale-container">
        <h1>Comparaison des régimes fiscaux pour la {{ $store.getters.findSocietyName(simulationDatas.societyId) }}</h1>

        <div class="navbar">
            <div :class="{'selected-window': selectedWindow == 'datas'}" @click="() => selectedWindow = 'datas'">Données de la simulation</div>
            <div :class="{'selected-window': selectedWindow == 'simulation'}" @click="() => selectedWindow = 'simulation'">Résultat de la simulation</div>
        </div>

        <div class="datas-frame" v-if="selectedWindow == 'datas'">
            <form class="simulation-form">
                <div class="form-section">
                    <label>Durée de la simulation en années</label>
                    <input type="number" v-model="body.simulation_duration">
                </div>

                <!-- <div class="form-section">
                    <label>Rendement annuel des investissements financiers (%)</label>
                    <input type="number" step="0.01" v-model="body.financial_investment_rate">
                </div> -->
                
                <div class="form-section">
                    <label>Revalorisation annuelle des biens immobiliers</label>
                    <input type="number" step="0.01" v-model="body.real_estate_gain_yearly"/>
                </div>

                <div class="form-section">
                    <label>Taux annuel de revalorisation des loyers (%)</label>
                    <input type="number" step="0.01" v-model="body.rental_adjustment_rate_yearly"/>
                </div>

                <div class="form-section">
                    <label>Taux annuel de revalorisation des charges (%)</label>
                    <input type="number" step="0.01" v-model="body.charges_adjustment_rate_yearly"/>
                </div>

                <div class="form-section">
                    <label>Taux marginal d'imposition (%)</label>
                    <select v-model="body.marginal_tax_bracket">
                        <option v-for="value in [0, 0.11, 0.3, 0.41, 0.45]" :key="value" :value="value">{{ value * 100 }} %</option>
                    </select>
                </div>
            </form>

            <h2>Personnaliser les données des actifs détenus par la SCI</h2>

            <div class="form-section">
                <select v-model="selectedAssetObj">
                    <option v-for="asset in $store.getters.findSocietyAssets(simulationDatas.societyId)" :key="asset._id" :value="asset">Données personnalisées de {{ asset.label || $store.getters.allTypes[asset.type] }} - {{ toEuro(asset.value) }}</option>
                </select>
            </div>
            

            <form v-if="selectedAssetObj">
                <div class="form-section">
                    <label>Revenus du bien</label>
                    <currency-input v-model="asset_income_object.value"/>
                </div>

                <div class="form-section">
                    <label>Charges du bien</label>
                    <currency-input v-model="asset_charge_object.value"/>
                </div>

                <h3>Tableau d'amortissement du bien</h3>

                <table class="amortization-table">
                    <tr>
                        <th>Composant</th>
                        <th>Quote-part (%)</th>
                        <th>Valeur du composant</th>
                        <th>Durée d'amortissement (en année)</th>
                        <th>Amortissement annuel</th>
                    </tr>

                    <tr>
                        <td>Terrain</td>
                        <td><input type="number" v-model="assetAmortizationTable.field_prorata_share"></td>
                        <td>{{ toEuro(selectedAssetObj.value * assetAmortizationTable.field_prorata_share / 100) }}</td>
                        <td>Non amortissable</td>
                        <td>{{ toEuro(0) }}</td>
                    </tr>

                    <tr>
                        <td>Gros œuvre</td>
                        <td><input type="number" v-model="assetAmortizationTable.structural_work_prorata_share"></td>
                        <td>{{ toEuro(selectedAssetObj.value * assetAmortizationTable.structural_work_prorata_share / 100) }}</td>
                        <td><input type="number" v-model="assetAmortizationTable.structural_work_amortization_period"></td>
                        <td>{{ toEuro((selectedAssetObj.value * assetAmortizationTable.structural_work_prorata_share / 100) / assetAmortizationTable.structural_work_amortization_period) }}</td>
                    </tr>

                    <tr>
                        <td>Façade</td>
                        <td><input type="number" v-model="assetAmortizationTable.facade_prorata_share"></td>
                        <td>{{ toEuro(selectedAssetObj.value * assetAmortizationTable.facade_prorata_share / 100) }}</td>
                        <td><input type="number" v-model="assetAmortizationTable.facade_amortization_period"></td>
                        <td>{{ toEuro((selectedAssetObj.value * assetAmortizationTable.facade_prorata_share / 100) / assetAmortizationTable.facade_amortization_period) }}</td>
                    </tr>

                    <tr>
                        <td>Installations générales</td>
                        <td><input type="number" v-model="assetAmortizationTable.general_facilities_prorata_share"></td>
                        <td>{{ toEuro(selectedAssetObj.value * assetAmortizationTable.general_facilities_prorata_share / 100) }}</td>
                        <td><input type="number" v-model="assetAmortizationTable.general_facilities_amortization_period"></td>
                        <td>{{ toEuro((selectedAssetObj.value * assetAmortizationTable.general_facilities_prorata_share / 100) / assetAmortizationTable.general_facilities_amortization_period) }}</td>
                    </tr>

                    <tr>
                        <td>Agencement</td>
                        <td><input type="number" v-model="assetAmortizationTable.layout_prorata_share"></td>
                        <td>{{ toEuro(selectedAssetObj.value * assetAmortizationTable.layout_prorata_share / 100) }}</td>
                        <td><input type="number" v-model="assetAmortizationTable.layout_amortization_period"></td>
                        <td>{{ toEuro((selectedAssetObj.value * assetAmortizationTable.layout_prorata_share / 100) / assetAmortizationTable.layout_amortization_period) }}</td>
                    </tr>

                    <tr>
                        <th>Total</th>
                        <th>{{ total_amortization_percentage }} %</th>
                        <th>{{ toEuro(selectedAssetObj.value) }}</th>
                        <th></th>
                        <th>{{ toEuro(amortization_value) }}</th>
                    </tr>
                </table>
            </form>
        </div>
        

        <div class="simulation-frame" v-else>
            <h3>Graphique évolution de la fiscalité</h3>
            <div class="graph-frame">
                <div v-if="labels && dataSets && labels.length > 0 && dataSets.length > 0">
                    <line-chart
                        :labels="labels"
                        :datasets="dataSets"
                    />
                </div>
                <p v-else>Chargement du graphique...</p>
            </div>
            

            <h3>Simulation de la translucidité fiscale</h3>
            <table class="comparison-table">
                <tr>
                    <th>Année</th>
                    <th v-for="(line, index) in comparisonResult.transparentSocietyResult" :key="index">{{ index + 1 }}</th>
                </tr>

                <tr>
                    <th>Revenus fonciers</th>
                    <td v-for="(line, index) in comparisonResult.transparentSocietyResult" :key="index">{{ toEuro(line.real_estate_income) }}</td>
                </tr>

                <tr>
                    <th>Charges foncières</th>
                    <td v-for="(line, index) in comparisonResult.transparentSocietyResult" :key="index">-{{ toEuro(line.real_estate_charges) }}</td>
                </tr>

                <tr>
                    <th>Emprunts</th>
                    <td v-for="(line, index) in comparisonResult.transparentSocietyResult" :key="index">-{{ toEuro(line.total_monthly_payment) }}</td>
                </tr>

                <tr>
                    <th>Impôt sur le revenu</th>
                    <td v-for="(line, index) in comparisonResult.transparentSocietyResult" :key="index">-{{ toEuro(line.income_tax) }}</td>
                </tr>

                <tr>
                    <th>Prélèvements sociaux</th>
                    <td v-for="(line, index) in comparisonResult.transparentSocietyResult" :key="index">-{{ toEuro(line.social_tax) }}</td>
                </tr>

                <tr>
                    <th>Cashflow</th>
                    <td v-for="(line, index) in comparisonResult.transparentSocietyResult" :key="index" :class="{'positive-td-value': line.cashflow > 0, 'negative-td-value': line.cashflow < 0}">{{ toEuro(line.cashflow) }}</td>
                </tr>
            </table>

            <h3>Simulation de l'impôt sur les sociétés</h3>
            <table class="comparison-table">
                <tr>
                    <th>Année</th>
                    <th v-for="(line, index) in comparisonResult.IsSocietyResult" :key="index">{{ index + 1 }}</th>
                </tr>

                <tr>
                    <th>Revenus fonciers</th>
                    <td v-for="(line, index) in comparisonResult.IsSocietyResult" :key="index">{{ toEuro(line.real_estate_income) }}</td>
                </tr>

                <tr>
                    <th>Charges foncières</th>
                    <td v-for="(line, index) in comparisonResult.IsSocietyResult" :key="index">-{{ toEuro(line.real_estate_charges) }}</td>
                </tr>

                <tr>
                    <th>Emprunts</th>
                    <td v-for="(line, index) in comparisonResult.IsSocietyResult" :key="index">-{{ toEuro(line.total_monthly_payment) }}</td>
                </tr>

                <tr>
                    <th>Amortissements</th>
                    <td v-for="(line, index) in comparisonResult.IsSocietyResult" :key="index">-{{ toEuro(line.total_amortization) }}</td>
                </tr>

                <tr>
                    <th>Impôt sur les sociétés</th>
                    <td v-for="(line, index) in comparisonResult.IsSocietyResult" :key="index">-{{ toEuro(line.society_taxes) }}</td>
                </tr>

                <tr>
                    <th>Cashflow</th>
                    <td v-for="(line, index) in comparisonResult.IsSocietyResult" :key="index" :class="{'positive-td-value': line.cashflow > 0, 'negative-td-value': line.cashflow < 0}">{{ toEuro(line.cashflow) }}</td>
                </tr>
            </table>
            
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    props: ['simulationDatas'],
    data() {
        return {
            selectedWindow: 'datas',
            labels: undefined, // Par défaut, une liste vide
            comparisonResult: {
                transparentSocietyResult: [],
                IsSocietyResult: [],
            }, // Par défaut, un objet vide pour éviter les erreurs d'accès
            dataSets: undefined, // Optionnel, s'il est directement utilisé ailleurs
            body: {
                simulation_duration: 15, // Valeur par défaut pour éviter undefined
                financial_investment_rate: 0.04, 
                real_estate_gain_yearly: 0.015,
                rental_adjustment_rate_yearly: 0.01,
                charges_adjustment_rate_yearly: 0.01,
                incomes_per_asset: [],
                charges_per_asset: [],
                amortization_table_per_asset: [],
                marginal_tax_bracket: 0.3, // Valeur par défaut
            },
            assetAmortizationTable: {
                asset_id: undefined, 
                field_prorata_share: 15,
                structural_work_prorata_share: 45,
                structural_work_amortization_period: 50, 
                facade_prorata_share: 10, 
                facade_amortization_period: 20, 
                general_facilities_prorata_share: 15,
                general_facilities_amortization_period: 15,
                layout_prorata_share: 15, 
                layout_amortization_period: 15, 
            },
            asset_income_object: undefined,
            asset_charge_object: undefined,
            selectedAssetObj: undefined,
        };
    },
    methods: {
        createAmortizationTableArray() {
            for (const asset of this.$store.getters.findSocietyAssets(this.simulationDatas.societyId)) {
                if (this.body.amortization_table_per_asset.find(at => at.asset_id == asset._id) == undefined) {
                    this.body.amortization_table_per_asset.push({
                        asset_id: asset._id, 
                        field_prorata_share: 15,
                        structural_work_prorata_share: 45,
                        structural_work_amortization_period: 50, 
                        facade_prorata_share: 10, 
                        facade_amortization_period: 20, 
                        general_facilities_prorata_share: 15,
                        general_facilities_amortization_period: 15,
                        layout_prorata_share: 15, 
                        layout_amortization_period: 15,
                    })
                }
            }
        },
        createListPerAsset() {
            // Revenus
            for (const asset of this.$store.getters.findSocietyAssets(this.simulationDatas.societyId)) {
                if (this.body.incomes_per_asset.find(i => i.asset_id == asset._id) == undefined) {
                    const assetIncome = this.$store.getters.getAllIncomes.find(i => i.linked_property == asset._id)

                    this.body.incomes_per_asset.push({
                        asset_id: asset._id, 
                        type: 'property_income',
                        value: assetIncome?.net_value || 0,
                    })
                }
            }
            // Charges
            for (const asset of this.$store.getters.findSocietyAssets(this.simulationDatas.societyId)) {
                if (this.body.charges_per_asset.find(i => i.asset_id == asset._id) == undefined) {
                    const assetIncome = this.$store.getters.getAllIncomes.find(i => i.linked_property == asset._id)

                    this.body.charges_per_asset.push({
                        asset_id: asset._id, 
                        type: 'property_charges',
                        value: assetIncome?.charges || 0,
                    })
                }
            }
        },
        generateDatasets(result) {
            const IrDatas = [];
            const IsDatas = [];
            let totalTax = 0;

            // Générez les données pour IR
            for (const line of result.transparentSocietyResult) {
                totalTax += line.income_tax + line.social_tax;
                IrDatas.push(totalTax);
            }

            // Générez les données pour IS
            totalTax = 0;
            for (const line of result.IsSocietyResult) {
                totalTax += line.society_taxes;
                IsDatas.push(totalTax);
            }

            return [
                {
                    label: "Option IR",
                    data: IrDatas,
                    borderColor: "rgb(66, 135, 245)",
                    pointBackgroundColor: "#0A1C6B",
                    pointRadius: "2",
                },
                {
                    label: "Option IS",
                    data: IsDatas,
                    borderColor: "rgb(251, 158, 75)",
                    pointBackgroundColor: "#FB9E4B",
                    pointRadius: "2",
                },
            ];
        },
        generateLabels() {
            const duration = this.body.simulation_duration || 0;
            return Array.from({ length: duration }, (_, i) => i + 1); // Génère des labels pour chaque année
        },
        async computeComparison() {
            try {
                this.showChart = false; // Désactive le rendu du graphique temporairement
                const payload = {
                    ...this.body,
                    debts: this.$store.getters.getAllDebts.filter(d => d.owner1 == this.society._id),
                    properties: this.$store.getters.getAllProperties.filter(p =>
                    p.detention.map(d => d.owner_id).includes(this.society._id)
                    ),
                };

                const res = await axios.post(
                    this.$store.getters.get_api_url + "simulators/society-tax-comparison",
                    payload,
                    {
                    headers: { authorization: "Bearer " + this.$store.getters.get_token },
                    }
                );

                this.comparisonResult = res.data; // Met à jour les données
                this.showChart = true; // Active le rendu du graphique

            } catch (error) {
                console.error("Erreur lors du calcul :", error);
            }
        },
        async saveSimulationDatas() {
            await axios.put(this.$store.getters.get_api_url + 'clients/society-tax-comparison/' + this.body._id, this.body,{
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })
        }
    },
    computed: {
        amortization_value() {
            if (this.selectedAssetObj) {
                let result = 0
                result += (this.selectedAssetObj.value * this.assetAmortizationTable.structural_work_prorata_share / 100) / this.assetAmortizationTable.structural_work_amortization_period
                result += (this.selectedAssetObj.value * this.assetAmortizationTable.facade_prorata_share / 100) / this.assetAmortizationTable.facade_amortization_period
                result += (this.selectedAssetObj.value * this.assetAmortizationTable.general_facilities_prorata_share / 100) / this.assetAmortizationTable.general_facilities_amortization_period
                result += (this.selectedAssetObj.value * this.assetAmortizationTable.layout_prorata_share / 100) / this.assetAmortizationTable.layout_amortization_period

                return result
            } else {
                return 0
            }
        },
        total_amortization_percentage() {
            if (this.selectedAssetObj) {
                let result = 0
                result += this.assetAmortizationTable.field_prorata_share
                result += this.assetAmortizationTable.structural_work_prorata_share
                result += this.assetAmortizationTable.facade_prorata_share
                result += this.assetAmortizationTable.general_facilities_prorata_share
                result += this.assetAmortizationTable.layout_prorata_share

                return result
            } else {
                return 0
            }
        },
    },
    watch: {
        selectedAssetObj: {
            deep: true,
            handler() {
                const amortization_table = this.body.amortization_table_per_asset.find(at => at.asset_id == this.selectedAssetObj._id)
                this.assetAmortizationTable = JSON.parse(JSON.stringify(amortization_table))

                const income_object = this.body.incomes_per_asset.find(i => i.asset_id == this.selectedAssetObj._id)
                this.asset_income_object = JSON.parse(JSON.stringify(income_object))

                const charge_object = this.body.charges_per_asset.find(i => i.asset_id == this.selectedAssetObj._id)
                this.asset_charge_object = JSON.parse(JSON.stringify(charge_object))
            }
        }, 
        assetAmortizationTable: {
            deep: true,
            handler() {
                const index = this.body.amortization_table_per_asset.map(at => at.asset_id).indexOf(this.assetAmortizationTable.asset_id)
                const new_amortization_table = JSON.parse(JSON.stringify(this.assetAmortizationTable))

                this.body.amortization_table_per_asset.splice(index, 1, new_amortization_table)
            }
        },
        asset_income_object: {
            deep: true,
            handler() {
                const index = this.body.incomes_per_asset.map(i => i.asset_id).indexOf(this.asset_income_object.asset_id)
                const new_income_object = JSON.parse(JSON.stringify(this.asset_income_object))

                this.body.incomes_per_asset.splice(index, 1, new_income_object)
            }
        },
        asset_charge_object: {
            deep: true,
            handler() {
                const index = this.body.charges_per_asset.map(i => i.asset_id).indexOf(this.asset_charge_object.asset_id)
                const new_charge_object = JSON.parse(JSON.stringify(this.asset_charge_object))

                this.body.charges_per_asset.splice(index, 1, new_charge_object)
            }
        },
        body: {
            deep: true, 
            handler() {
                this.computeComparison()
                this.saveSimulationDatas()
            }
        },
        comparisonResult: {
            deep: true,
            handler(newVal) {
                // Regénère les datasets et labels à partir des nouvelles données
                if (newVal) {
                    this.dataSets = this.generateDatasets(newVal);
                    this.labels = this.generateLabels(newVal);
                }
            },
        },

    },
    mounted() {
        this.society = this.$store.getters.getAllSocieties.find(s => s._id == this.simulationDatas.societyId)

        this.body = JSON.parse(JSON.stringify(this.simulationDatas))

        this.createAmortizationTableArray()
        this.createListPerAsset()

        this.selectedAssetObj = this.$store.getters.findSocietyAssets(this.simulationDatas.societyId)[0]
    }
}
</script>

<style scoped>
.modale-container {
    padding: 20px;
}

h1 {
    text-align: center;
}

.navbar {
    width: 100%;
    display: flex;
}
.navbar div {
    cursor: pointer;
    flex-basis: 50%;
    text-align: center;
    font-size: 22px;
    padding: 15px;
    border: lightgrey 1px solid;
    font-weight: bold;
}

.navbar div:hover {
    background-color: #0a1c6b6a;
}

.selected-window {
    background-color: #0a1c6b;
    color: white;
}

.simulation-form {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    padding: 20px ;
    justify-content: space-between;
}

.form-section {
    flex-wrap: nowrap;
    flex-basis: 40%;
    padding: 10px;
}
.form-section label,
.form-section input,
.form-section select {
    font-size: 18px;
}
.form-section label {
    font-weight: bold;
}
.form-section input,
.form-section select {
    width: 100%;
    border-radius: 5px;
    padding: 5px 10px;
    border: 1px solid lightgrey;
}

.amortization-table {
    width: 100%;
    border-collapse: collapse;
}

.amortization-table td, 
.amortization-table th {
    padding: 5px;
    border: black 1px solid;
    position: relative;
    border: solid lightgrey 1px;
    text-align: center;
    font-size: 16px;
}
.amortization-table th {
    background-color: #F8F4EB;
}

.amortization-table tr td:first-child {
    text-align: left;
}

.amortization-table td input,
.amortization-table td select {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 0;
    border: none;
    text-align: center;
    font-size: 16px;
}

.simulation-frame h3 {
    color: #0A1C6B;
    font-size: 24px;
    text-decoration: underline;
}

.comparison-table {
    border-collapse: collapse;
    width: 100%;
}
.comparison-table td, th {
    text-align: center;
    border: solid #BE9C5D 1px;
    padding: 10px;
}
.comparison-table th {
    background-color: #F8F4EB;
}

.positive-td-value {
    background-color: lightgreen;
}
.negative-td-value {
    background-color: lightcoral;
}

.graph-frame {
    display: flex;
    justify-content: center;
    width: 100%;
}
.graph-frame div {
    flex-basis: 75%;
}
</style>