<template>
    <div>
        <div class="menu">
            <div :class="{'menu-selection': !show_detailed_result}" @click="show_detailed_result=false">Données de simulation</div>
            <div :class="{'menu-selection': show_detailed_result, 'menu-disabled': computationResult == undefined}" @click="computationResult ? show_detailed_result=true : null">Résultat détaillé</div>
        </div>

        <div class="calculator-frame">

            <div class="left-section" v-if="!show_detailed_result">
                <div class="section-element">
                    <h3>Foyer fiscal</h3>

                    <div class="form-section">
                        <div class="input-frame">
                            <label>Calcul pour un couple fiscal</label>
                            <select v-model="is_couple">
                                <option :value="true">OUI</option>
                                <option :value="false">NON</option>
                            </select>
                        </div>

                        <div class="input-frame">
                            <label>Nombre d'enfants à charge</label>
                            <input type="number" v-model="nb_attached_children">
                        </div>
                    </div>

                    <div class="form-section">
                        <div class="input-frame">
                            <label>PFU sur les revenus financiers</label>
                            <input type="checkbox" v-model="pfu_option">
                        </div>
                    </div>
                </div>

                <div class="section-element">
                    <h3>Revenus du foyer</h3>

                    <div class="form-section">
                        <div class="input-frame">
                            <label>Revenus professionnels</label>
                            <currency-input v-model="user_professionnal_income"/>
                        </div>

                        <div class="input-frame" v-if="is_couple">
                            <label>Revenus professionnels du conjoint</label>
                            <currency-input v-model="spouse_professionnal_income"/>
                        </div>
                    </div>

                    <div class="form-section">
                        <div class="input-frame">
                            <label>Pension de retraite</label>
                            <currency-input v-model="user_retirement_income"/>
                        </div>

                        <div class="input-frame" v-if="is_couple">
                            <label>Pension de retraite du conjoint</label>
                            <currency-input v-model="spouse_retirement_income"/>
                        </div>
                    </div>

                    <div class="form-section">
                        <div class="input-frame">
                            <label>Revenus locatifs du foyer</label>
                            <currency-input v-model="real_estate_income"/>
                        </div>
                    </div>

                    <div class="form-section">
                        <div class="input-frame">
                            <label>Dividendes du foyer</label>
                            <currency-input v-model="dividends"/>
                        </div>
                    </div>
                </div>

                <div class="section-element btn-section">
                    <div class="btn-send-computation btn-grow" @click="computeResult">Calculer</div>
                </div>
            </div>

            <div class="left-section" v-else>
                <div class="section-element">
                    <h3>Liquidation de l'impôt</h3>

                    <table>
                        <tr class="highlight">
                            <th></th>
                            <td v-for="index in nb_declarants" :key="index">Déclarant {{ index }}</td>
                        </tr>

                        <tr v-if="total_salaries > 0">
                            <th>Traitements et salaires</th>
                            <td v-for="(person, index) in computationResult.rbgComputation.total_incomes_list" :key="index">{{ toEuro(person.total_salaries) }}</td>
                        </tr>

                        <tr v-if="total_salaries > 0">
                            <th>Charges déductibles</th>
                            <td v-for="(person, index) in computationResult.rbgComputation.total_charges_list" :key="index">- {{ toEuro(person.salaries_charges) }}</td>
                        </tr>

                        <tr v-if="total_retirement > 0">
                            <th>Pensions de retraite</th>
                            <td v-for="(person, index) in computationResult.rbgComputation.total_incomes_list" :key="index">{{ toEuro(person.total_retirement) }}</td>
                        </tr>

                        <tr v-if="total_retirement > 0">
                            <th>Charges déductibles</th>
                            <td v-for="(person, index) in computationResult.rbgComputation.total_charges_list" :key="index">- {{ toEuro(person.retirement_charges) }}</td>
                        </tr>

                        <tr v-if="total_properties_income > 0">
                            <th>Revenus fonciers</th>
                            <td v-for="(person, index) in computationResult.rbgComputation.total_incomes_list" :key="index">{{ toEuro(person.total_property_incomes) }}</td>
                        </tr>

                        <tr v-if="total_properties_income > 0">
                            <th>Charges déductibles</th>
                            <td v-for="(person, index) in computationResult.rbgComputation.total_charges_list" :key="index">- {{ toEuro(person.property_incomes_charges) }}</td>
                        </tr>

                        <tr v-if="total_dividends > 0">
                            <th>Dividendes</th>
                            <td v-for="(person, index) in computationResult.rbgComputation.total_incomes_list" :key="index">{{ toEuro(person.dividend) }}</td>
                        </tr>

                        <tr v-if="total_dividends > 0">
                            <th>Abattement</th>
                            <td v-for="(person, index) in computationResult.rbgComputation.total_charges_list" :key="index">- {{ toEuro(person.dividend) }}</td>
                        </tr>

                        <tr class="highlight">
                            <th>Revenu brut global</th>
                            <td :colspan="nb_declarants"></td>
                            <td>{{ toEuro(computationResult.rbgComputation.rbg) }}</td>
                        </tr>

                        <tr class="highlight">
                            <th>Revenu net imposable</th>
                            <td :colspan="nb_declarants"></td>
                            <td>{{ toEuro(computationResult.rniComputation?.rni) }}</td>
                        </tr>

                        <tr>
                            <th>Impôt sur le revenu (barème)</th>
                            <td :colspan="nb_declarants"></td>
                            <td>{{ toEuro(computationResult.netTaxes?.taxes) }}</td>
                        </tr>

                        <tr>
                            <th>Impôt sur le revenu (PFU)</th>
                            <td :colspan="nb_declarants"></td>
                            <td>{{ toEuro(computationResult.pfu) }}</td>
                        </tr>

                        <tr class="highlight">
                            <th>Impôt total à payer</th>
                            <td :colspan="nb_declarants"></td>
                            <td>{{ toEuro(computationResult.netTaxes?.taxes + computationResult.pfu) }}</td>
                        </tr>
                    </table>
                </div>

                <div class="section-element">
                    <h3>Données d'analyse</h3>

                    <table>
                        <tr>
                            <th>Tranche marginale d'imposition</th>
                            <td>{{ computationResult.tmi }} %</td>
                        </tr>

                        <tr>
                            <th>Taux moyen d'imposition</th>
                            <td>{{ Math.round((computationResult.netTaxes?.taxes / totalIncomeToScale) * 100) }} %</td>
                        </tr>
                    </table>
                </div>
            </div>

            <div class="right-section">
                <div class="section-element" v-if="!computationResult">
                    <h3>Résultats</h3>

                    <img src="../ressources/img/illustration.png"/>

                    <p>Lancez le calcul pour obtenir un résultat</p>
                </div>

                <div class="section-element" v-if="computationResult">
                    <h3>Revenu imposable</h3>
                    <h4>{{ toEuro(computationResult.rniComputation.rni) }}</h4>
                </div>

                <div class="section-element" v-if="computationResult">
                    <h3>Impôt à payer</h3>
                    <h4>{{ toEuro(computationResult.netTaxes?.taxes + computationResult.pfu) }}</h4>
                </div>

                <div class="section-element" v-if="computationResult">
                    <h3>Quotient familial</h3>
                    <h4>{{ computationResult.FamilyQuotient.nb_part_couple + computationResult.FamilyQuotient.nb_part_dependant }} part(s)</h4>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            show_detailed_result: false,

            is_couple: false,
            nb_attached_children: 0,
            pfu_option: true,
            user_professionnal_income: 100000,
            spouse_professionnal_income: 0,
            user_retirement_income: 0,
            spouse_retirement_income: 0,
            real_estate_income: 0,
            dividends: 0,

            computationResult: undefined,
        }
    },
    computed: {
        totalIncomeToScale() {
            let total = this.user_professionnal_income
            total += this.spouse_professionnal_income
            total += this.user_retirement_income
            total += this.spouse_retirement_income
            total += this.real_estate_income

            if (!this.pfu_option) {
                total += this.dividends
            }

            return total
        },
        requestBody() {
            let body = {
                couple: {
                    situation: (this.is_couple ? 'married' : 'alone')
                },
                persons: [
                    {
                        _id: 'user1',
                        role: 'user',
                        birthdate: new Date(),
                        is_handicaped: false,
                        is_veteran: false,
                    },
                ],
                incomes: [],
                properties: [],
                assets: [],
                societies: [],
                tax_reductions: [],
                deductible_expenses: [],
            }

            // Membres du foyer fiscal
            if (this.is_couple) {
                body.persons.push({ _id: 'spouse1', role: 'spouse', birthdate: new Date(), is_handicaped: false, is_veteran: false, })
            }

            for (let i = 0 ; i < this.nb_attached_children ; i++) {
                body.persons.push({ _id: 'child' + i, role: 'child', birthdate: new Date(), is_handicaped: false, is_veteran: false, is_attached: true, lives_at_home: true, situation: 'alone' })
            }

            // Revenus
            if (this.user_professionnal_income > 0) {
                body.incomes.push({ _id: 'user_professionnal_income', type: 'salary', net_value: this.user_professionnal_income, person_id: 'user1', isMicro: true })
            }

            if (this.spouse_professionnal_income > 0 && this.is_couple) {
                body.incomes.push({ _id: 'spouse_professionnal_income', type: 'salary', net_value: this.spouse_professionnal_income, person_id: 'spouse1', isMicro: true })
            }

            if (this.user_retirement_income > 0) {
                body.incomes.push({ _id: 'user_retirement_income', type: 'retirement', net_value: this.user_retirement_income, person_id: 'user1', isMicro: true })
            }

            if (this.spouse_retirement_income > 0 && this.is_couple) {
                body.incomes.push({ _id: 'spouse_retirement_income', type: 'retirement', net_value: this.spouse_retirement_income, person_id: 'spouse1', isMicro: true })
            }

            if (this.real_estate_income > 0) {
                body.incomes.push({ _id: 'real_estate_income', type: 'property_income', net_value: this.real_estate_income, person_id: 'user1', isMicro: true })
            }

            if (this.dividends > 0) {
                body.incomes.push({ _id: 'dividends', type: 'dividend', net_value: this.dividends, person_id: 'user1', isMicro: true })
            }

            return {
                data: body, 
                choices: {
                    bpi_pvmo: !this.pfu_option
                }
            }
        },
        nb_declarants() {
            if (this.computationResult) {
                return Object.keys(this.computationResult.rbgComputation.total_incomes_list).length
            } else {
                return 0
            }
        },
        total_salaries() {
            if (this.computationResult) {
                return Object.keys(this.computationResult.rbgComputation.total_incomes_list).map(key => this.computationResult.rbgComputation.total_incomes_list[key].total_salaries).reduce((sum, current) => sum + current, 0)
            } else {
                return 0
            }
        },
        total_retirement() {
            if (this.computationResult) {
                return Object.keys(this.computationResult.rbgComputation.total_incomes_list).map(key => this.computationResult.rbgComputation.total_incomes_list[key].total_retirement).reduce((sum, current) => sum + current, 0)
            } else {
                return 0
            }
        },
        total_properties_income() {
            if (this.computationResult) {
                return Object.keys(this.computationResult.rbgComputation.total_incomes_list).map(key => this.computationResult.rbgComputation.total_incomes_list[key].total_property_incomes).reduce((sum, current) => sum + current, 0)
            } else {
                return 0
            }
        },
        total_dividends() {
            if (this.computationResult) {
                return Object.keys(this.computationResult.rbgComputation.total_incomes_list).map(key => this.computationResult.rbgComputation.total_incomes_list[key].dividend).reduce((sum, current) => sum + current, 0)
            } else {
                return 0
            }
        },
    },
    methods: {
        async computeResult() {
            
            const res = await axios.post(this.$store.getters.get_api_url + 'simulators/income-taxes', this.requestBody, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            this.computationResult = res.data
        }
    }
}
</script>

<style src="./style.css" scoped></style>