<template>
    <div>
        <h2>Fiscalité</h2>
        <ul>
            <li>
                <router-link to="calculettes/impot-sur-le-revenu">Fiscalité sur le revenu</router-link>
            </li>

            <li>
                <router-link to="calculettes/impot-fortune-immobiliere">Estimation de l'IFI</router-link>
            </li>

            <li>
                <router-link to="#">Contributions sur les hauts revenus <span>- Endpoint API à créer</span></router-link>
            </li>

            <li>
                <router-link to="calculettes/notary-fees">Frais de notaire</router-link>
            </li>
        </ul>

        <h2>Dirigeants d'entreprise</h2>
        <ul>
            <li>
                <router-link to="calculettes/social-charges">Cotisations sociales</router-link>
            </li>

            <li>
                <router-link to="calculettes/manager-remuneration">Rémunération du dirigeant</router-link>
            </li>

            <li>
                <router-link to="calculettes/pv-cession-societe">Fiscalité de plus-value sur cession d'entreprise</router-link>
            </li>
        </ul>

        <h2>Immobilier</h2>
        <ul>
            <li>
                <router-link to="calculettes/mensualite-emprunt">Mensualité d'un emprunt</router-link>
            </li>

            <li>
                <router-link to="#">Capacité d'emprunt <span>- Endpoint API à créer</span></router-link>
            </li>

            <li>
                <router-link to="#">Régime fiscal d'une SCI <span>- A brancher</span></router-link>
            </li>
            
            <li>
                <router-link to="calculettes/plus-value-immobiliere-particuliers">Fiscalité d'une plus-value immobilière (particuliers)</router-link>
            </li>
        </ul>

        <h2>Transmission</h2>
        <ul>
            <li>
                <router-link to="calculettes/succession-simplifiee">Liquidation simple d'une succession</router-link>
            </li>

            <li>
                <router-link to="calculettes/dmtg-donation">DMTG sur une donation</router-link>
            </li>
        </ul>

        <h2>Investissement</h2>
        <ul>
            <li>
                <router-link to="#">Évolution d'un investissement <span>- Endpoint API à créer</span></router-link>
            </li>

            <li>
                <router-link to="#">Rachats en assurance vie <span>- Endpoint API à créer</span></router-link>
            </li>

            <li>
                <router-link to="#">Valeur de l'usufruit à terme fixe <span>- Endpoint API à créer</span></router-link>
            </li>
        </ul>

        <h2>Retraite</h2>
        <ul>
            <li>
                <router-link to="calculettes/ris-analysis">Analyse du RIS</router-link>
            </li>

            <li>
                <router-link to="#">Rente viagère <span>- A brancher</span></router-link>
            </li>
        </ul>
    </div>
</template>

<style scoped>
a {
    color: white;
    text-decoration: none;
}

a:hover {
    text-decoration: underline
}

li {
    list-style-type: none;
    font-size: 22px;
    padding: 10px;
    margin: 5px;
    border: 1px solid black;
    border-radius: 5px;
    background-color: rgb(1, 1, 81);
}

span {
    color: orange;
}
</style>