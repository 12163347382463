<template>
    <loader-spin v-if="showLoader"></loader-spin>

    <form class="add-form">
        <div class="form-section">
            <label for="situation">Situation familiale</label>
            <select id="situation" v-model="body.situation">
                <option 
                    v-for="(value, key) in $store.getters.maritalSituations" :key="key" :value="key">
                    {{ value }}
                </option>
            </select>
        </div>

        <div class="form-section" v-if="!['free_union', 'alone', 'widowed'].includes(body.situation)">
            <label for="regmat">Régime matrimonial</label>
            <select id="regmat" v-model="body.regmat">
                <option v-for="(value, key) in $store.getters.maritalContracts[body.situation]" :key="key" :value="key">{{ value }}</option>
            </select>
        </div>

        <!-- Clauses du contrat -->

        <!-- Société d'acquêts -->
        <div class="form-section" v-if="['separation'].includes(body.regmat)">
            <label for="ddv1">Société d'acquêts</label>
            <input type="checkbox" v-model="body.acquest_society">
        </div>

        <!-- Attribution intégrale -->
        <div class="form-section" v-if="body.regmat=='universal_community'">
            <label for="ddv1">Clause d'attribution intégrale</label>
            <input type="checkbox" v-model="body.full_attribution">
        </div>

        <!-- Répartition inégale -->
        <div class="form-section" v-if="hasCommunity && !body.full_attribution">
            <label for="repartition">Clause de répartition inégale</label>
            <input type="checkbox" v-model="body.inegal_repartition">
        </div>

        <div class="form-section" v-if="body.inegal_repartition">
            <label for="repartition1">Part de {{ $store.getters.userName }}</label>
            <input type="number" v-model="body.inegal_repartition_detail[$store.getters.userId]">
        </div>

        <div class="form-section" v-if="body.inegal_repartition">
            <label for="repartition2">Part de {{ $store.getters.spouseName }}</label>
            <input type="number" v-model="body.inegal_repartition_detail[$store.getters.spouseId]">
        </div>

        <!-- Préciput -->
        <div class="form-section" v-if="hasCommunity">
            <label for="preciput">Clause de préciput</label>
            <input type="checkbox" v-model="body.preciput">
        </div>

        <div class="form-section" v-if="body.preciput">
            <select id="regmat" v-model="body.preciput_detail" multiple>
                <option
                    v-for="property in $store.getters.getAllCommonProperties" :key="property._id" :value="property._id">
                    {{ property.label || $store.getters.allTypes[property.type] }} - {{ toEuro(property.value) }}
                </option>
            </select>
        </div>

        <!-- Donations au dernier vivant -->
        <div class="form-section" v-if="body.situation=='married'">
            <label for="ddv1">Donation au dernier vivant par {{ $store.getters.userName }}</label>
            <input type="checkbox" v-model="body.ddv_userforspouse">
        </div>

        <div class="form-section" v-if="body.situation=='married'">
            <label for="ddv1">Donation au dernier vivant par {{ $store.getters.spouseName }}</label>
            <input type="checkbox" v-model="body.ddv_spouseforuser">
        </div>

        <div class="btn-submit" @click="saveContract">Enregistrer</div>
    </form>
</template>

<script>

export default {
    emits: ["toggleModale"],
    data() {
        return {
            original_situation : undefined,
            showLoader: false,
            body: {
                _id: undefined,
                clientId: undefined,
                scenarioId: undefined,
                situation: "married",
                regmat: "aquest_community",
                full_attribution: false,
                acquest_society: false,
                inegal_repartition: false,
                inegal_repartition_detail: {},
                preciput: false,
                preciput_detail: [],
                ddv_userforspouse: false,
                ddv_spouseforuser: false
            },
        }
    },
    methods: {
        async saveContract() {
            this.showLoader = true
            const couple_situations = ['free_union', 'pacs', 'married']
            if (couple_situations.includes(this.body.situation) && !couple_situations.includes(this.original_situation)) {
                // Création d'un conjoint
                this.$store.dispatch('create_person', {
                    clientId   : this.$route.params.clientId,
                    scenarioId : this.$route.params.scenarioId,
                    civility   : 'mrs',
                    role       : "spouse",
                    firstname  : "Anonyme",
                    lastname   : "Anonyme",
                    birthdate  : "1953-01-01",
                    addToStore : true,
                })

                
                
            } else if (!couple_situations.includes(this.body.situation) && couple_situations.includes(this.original_situation)) {
                // Suppression du conjoint
                await this.$store.dispatch('delete_person', this.$store.getters.spouseId)
                this.$store.dispatch('importScenario', this.$route.params.scenarioId)
            }

            this.$store.dispatch('action_modify_couple', this.body)
            this.$emit('toggleModale')
            this.showLoader = false
        }
    },
    computed: {
        hasCommunity() {
            if (['universal_community', 'aquest_community', 'aquest_movable_community'].includes(this.body.regmat)) {
                return true
            } else if (this.body.regmat == 'separation' && this.body.acquest_society) {
                return true
            } else {
                return false
            }
        },
        isOriginalScenario() {
            const routeArray = this.$route.fullPath.split('/')

            if (routeArray[3] == 'profile' || this.$route.query.isOriginal) {
                return true
            } else {
                return false
            }
        }
    },
    watch: {
        'body.situation': function() {
            
            if (["alone", "widowed", "divorced"].includes(this.body.situation)) {
                this.body.regmat = undefined
                this.body.full_attribution = undefined
                this.body.acquest_society = undefined
                this.body.inegal_repartition = undefined
                this.body.inegal_repartition_detail = {}
                this.body.preciput = undefined
                this.body.preciput_detail = []
                this.body.ddv_userforspouse = undefined
                this.body.ddv_spouseforuser = undefined

            } else if (this.body.situation=="free_union") {
                this.body.regmat = undefined
                this.body.full_attribution = undefined
                this.body.acquest_society = undefined
                this.body.inegal_repartition = undefined
                this.body.inegal_repartition_detail = undefined
                this.body.preciput = undefined
                this.body.preciput_detail = []
                this.body.ddv_userforspouse = undefined
                this.body.ddv_spouseforuser = undefined

            } else if (this.body.situation=="pacs") {
                this.body.regmat = 'separation'
                this.body.full_attribution = undefined
                this.body.acquest_society = undefined
                this.body.inegal_repartition = undefined
                this.body.inegal_repartition_detail = {}
                this.body.preciput = undefined
                this.body.preciput_detail = []
                this.body.ddv_userforspouse = undefined
                this.body.ddv_spouseforuser = undefined

            } else {
                this.body.regmat = 'aquest_community'
            }
        },
        "body.regmat": function() {
            if (['séparation des biens', 'participation aux acquêts'].includes(this.body.regmat)) {
                this.body.full_attribution = false
                this.body.inegal_repartition = false
                this.body.inegal_repartition_detail = {}
                this.body.preciput = false
                this.body.preciput_detail = []

            } else if (['communauté réduite aux acquêts', 'communauté de meubles et acquêts'].includes(this.body.regmat)) {
                this.body.acquest_society = false
                this.body.full_attribution = false

            } else if (this.body.regmat == 'communauté universelle') {
                this.body.acquest_society = false
            }
        },
        'body.full_attribution': function() {
            if (this.body.full_attribution == true) {
                this.body.acquest_society = false
            }
        },
    },
    mounted() {
        this.body = this.$store.getters.getContract
        if (!this.body.inegal_repartition_detail) {
            this.body.inegal_repartition_detail = {}
        }

        this.original_situation = this.body.situation
    }
}
</script>

<style src="./style.css" scoped></style>