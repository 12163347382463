<template>
    <form class="add-form">
        <div class="form-section">
            <label for="category">Catégorie</label>
            <select id="category" v-model="body.category">
                <option v-for="(value, key) in $store.getters.assetCategories" :key="key" :value="key">{{ value }}</option>
            </select>
        </div>

        <div class="form-section">
            <label for="type">Type d'actif</label>
            <select id="type" v-model="body.type">
                <option v-for="(value, key) in $store.getters.assetTypes[body.category]" :key="key" :value="key">{{ value }}</option>
            </select>
        </div>

        <div class="form-section" v-if="body.type == 'cca'">
            <label for="type">Société débitrice</label>
            <select id="type" v-model="body.linkedSociety">
                <option v-for="society in $store.getters.getAllSocieties" :key="society._id" :value="society._id">{{ society.type.toUpperCase() }} - {{ society.label }}</option>
            </select>
        </div>

        <div class="form-section">
            <label for="value">Valeur du bien</label>
            <currency-input v-model="body.value" :disabled="!isNew"/>
        </div>

        <div class="form-section" v-if="!isNew">
            <label for="value">Retrait ou versement pour la valeur</label>
            <value-update @valueUpdate="updateValue"/>
        </div>

        <div class="form-section">
            <label for="date">Date de souscription</label>
            <input id="date" type="date" v-model="body.opening_date"/>
        </div>

        <div class="form-section">
            <label for="detention">Indivision / détention complexe</label>
            <input type="checkbox" v-model="body.isIndivision">
        </div>

        <div class="form-section" v-if="!body.isIndivision">
            <label for="owner">Propriétaire</label>
            <select id="owner" v-model="owner">
                <option v-for="(value, index) in $store.getters.findOwners" :key="index" :value="value">{{ $store.getters.findOwnerName(value) }}</option>
            </select>
        </div>

        <div class="form-section" v-if="!body.isIndivision">
            <label for="rights">Droits</label>
            <select id="rights" v-model="right">
                <option v-for="(value, key) in $store.getters.rights" :key="key" :value="key">{{ value }}</option>
            </select>
        </div>

        <detention-parts :detention="body.detention" @updated="updateDetention" v-if="body.isIndivision"/>

        <div class="btn-submit grow-effect" @click="submit">Enregistrer</div>
        <p class="errorMsg">{{ errorMsg }}</p>
    </form>
</template>

<script>
export default {
    props: ['import'],
    data() {
        return {
            isNew: true,
            owner: undefined,
            right: 'pp',
            errorMsg: "",
            value_update: 0,
            body: {
                _id          : undefined,
                clientId     : undefined,
                scenarioId   : undefined,
                category     : "bank",
                type         : 'compte courant',
                value        : 0,
                payment      : 0,
                isIndivision : false,
                detention    : [],
                opening_date : undefined,
                linkedSociety: '',
                isIpCreation : false,
            },
        }
    },
    watch: {
        owner() {
            this.body.detention = []

            if (this.owner == "commun") {
                this.body.detention.push({ owner_id : this.$store.getters.userId, right : this.right, part : 0.5 })
                this.body.detention.push({ owner_id : this.$store.getters.spouseId, right : this.right, part : 0.5 })
            } else {
                this.body.detention.push({ owner_id : this.owner, right : this.right, part : 1 })
            }
        },
        right() {
            let new_detention = []
            this.body.detention.forEach(part => {
                new_detention.push({
                    owner_id: part.owner_id,
                    right: this.right,
                    part: part.part
                })
            })

            this.body.detention = new_detention
        },
    },
    computed: {
        isOriginalScenario() {
            const routeArray = this.$route.fullPath.split('/')

            if (routeArray[3] == 'profile' || this.$route.query.isOriginal) {
                return true
            } else {
                return false
            }
        },
    },
    methods: {
        updateValue(value) {
            this.value_update = value
        },
        updateDetention(detention) {
            this.body.detention = detention
        },
        submit() {
            if (this.isOriginalScenario) {
                this.body.isIpCreation = false
            } else if (this.isNew) {
                this.body.isIpCreation = true
            }
            
            if (this.body.type == undefined) {
                this.errorMsg = "Précisez le type d'actif"

            } else if (this.body.detention.length < 1) {
                this.errorMsg = "Renseignez au moins un propriétaire"

            }  else if (this.body.type == 'cca' && !this.body.linkedSociety) {
                this.errorMsg = "Renseignez la société débitrice"

            } else {
                this.errorMsg = ""
                
                if (this.isNew) {
                    this.body.addToStore = true
                    this.$store.dispatch('create_asset', this.body)

                } else {
                    this.body.value += this.value_update
                    this.$store.dispatch('action_modify_asset', this.body)
                }  

                this.$emit('closeModale')
            }
        },
    },
    mounted() {

        if (this.import) {
            this.isNew = false
            this.body = JSON.parse(JSON.stringify(this.import))
            this.body.opening_date = this.body.opening_date?.slice(0,10)

            if (!this.body.isIndivision) {
                if (this.body.detention.length > 1) {
                    this.owner = 'commun'
                } else {
                    this.owner = this.body.detention[0].owner_id
                }
            }
        }

        if (this.isNew) {
            this.body.clientId = this.$route.params.clientId
            this.body.scenarioId = this.$route.params.scenarioId
        }
    }
}
</script>

<style src="./style.css" scoped></style>

