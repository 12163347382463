<template>
  <NavBar />
  <div class="page" v-if="showPage">
    <router-view></router-view>
  </div>

  <alert-display :isError="true" :msg="$store.state.errorMsg"/>
  <alert-display :isError="false" :msg="$store.state.validationMsg"/>
</template>

<script>
import axios from "axios";
import NavBar from "./components/NavBar.vue";

export default {
  components: {
    NavBar,
  },
  data() {
    return {
      showPage: false,
    }
  },
  async beforeMount() {
    const token = localStorage.getItem("token");
    const adminId = localStorage.getItem("adminId");

    try {
      const admin = await axios.get(this.$store.getters.get_api_url + "admins/getone/" + adminId, {
        headers: { authorization: "Bearer " + token },
      })

      this.$store.commit("setAdmin", admin.data);
      this.$store.commit("setAuth", {
        token: token,
        adminId: adminId,
      });

    } catch(err) {
      localStorage.removeItem("token");
      localStorage.removeItem("adminId")
      this.$router.replace("/connexion");
    }

    this.showPage = true
  },
};
</script>

<style>

body {
  margin: 0;
  background-color: rgb(243, 245, 247);
  font-family: "Avenir", sans-serif;
}

.page {
  position: relative;
}

/* Table des listes d'éléments */
.table-elements {
  width: 100%;
  table-layout: fixed;
  border-spacing: 0;
}
.table-elements td {
  border: 0.5px solid rgb(250, 250, 250);
  padding: 5px 3px;
  text-align: center;
  color: black;
  font-size: 12px;
}
.table-elements tr:first-child td {
  border-top: 0;
}
.table-elements tr td:first-child {
  border-left: 0;
}
.table-elements tr:last-child td {
  border-bottom: 0;
}
.table-elements tr td:last-child {
  border-right: 0;
}


/* organisation des sections */
.container {
  width: 90%;
  margin: auto;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}
.side-left,
.side-right {
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 5px;
}

/* style partagé par les sections */
.list-section {
  margin: 15px;
  background-color: white;
  border-radius: 10px;
}
.section-title {
  font-size: 13px;
  color: rgb(1, 1, 71);
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
}

.btn {
  cursor: pointer;
}

/* animations */
.grow-effect {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.grow-effect:hover {
  transform: scale(1.05);
}

input[type="checkbox"] {
  height: 15px;
  width: 15px;
}
</style>