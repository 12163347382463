<template>
    <div>

        <h1>Questionnaire de connaissance client</h1>
        <p>Ce questionnaire est important pour que nous puissions appréhender au mieux les objectifs de vos clients.</p>

        <form>
            <h3>Profil de risque</h3>
            <div class="survey-data-section">
                <select v-model="kyc_body.risk_profil">
                    <option v-for="(value, key) in risk_profils" :key="key" :value="key">{{ value }}</option>
                </select>
            </div>

            <h3>Horizon d'investissement</h3>
            <div class="survey-data-section">
                <select v-model="kyc_body.investment_period">
                    <option v-for="(value, key) in investment_periods" :key="key" :value="key">{{ value }}</option>
                </select>
            </div>

            <h3>Objectif principal de la mission de conseil</h3>
            <div class="survey-data-section">
                <select v-model="kyc_body.subject_of_recommandation">
                    <option v-for="(goal, key) in main_goals" :key="key" :value="key">{{ goal }}</option>
                </select>
            </div>

            <h3>Objectifs secondaires de la mission de conseil (2 max)</h3>
            <div class="survey-data-section" v-for="(goal, index) in goals_without_subject" :key="index">
                <label>{{ main_goals[goal] }}</label>
                <input type="checkbox" v-model="kyc_body[`${goal}_goal`]" :disabled="nb_goals_selected > 2 && kyc_body[`${goal}_goal`] == false">
            </div>
        </form>

        <form class="legacy-goals-questions" v-if="kyc_body.legacy_goal">

        </form>

        <div class="actions-btns">
            <div class="btn audit-call" @click="toggleDocModale">Bilan instantané</div>
            <div class="btn advice-call" @click="goToSimplifiedRecommendation">Créer une préconisation</div>
            <div class="btn ip-call" @click="createRecommendation">Faire appel à un IP</div>

            <modale :show="showDocModale" @toggle="toggleDocModale">
                <!-- Téléchargement du bilan auto -->
                <div class="survey-data-section">
                    <label>Style à appliquer</label>
                    <select v-model="style">
                        <option v-for="(style, key) in $store.getters.partnerStyles" :key="key" :value="key">{{ style }}</option>
                    </select>
                </div>

                <loader-spin v-if="isLoading"></loader-spin>
                <div v-else class="btn btn-download" @click="downloadDoc">Télécharger</div>
            </modale>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            kyc_body: {
                risk_profil: "cautious",
                investment_period: "more_than_10",
                subject_of_recommandation: "legacy",
                legacy_goal: true,
                retirement_goal: false,
                taxes_optimisation_goal: false,
                wealth_development_goal: false,
                new_incomes_goal: false,
            },
            risk_profils: {
                cautious: "Prudent",
                moderate: "Modéré",
                risky: "Risqué",
            },
            investment_periods: {
                less_than_1: "Moins d'un an",
                less_than_3: "Moins de 3 ans",
                less_than_6: "Moins de 6 ans",
                less_than_10: "Moins de 10 ans",
                more_than_10: "Plus de 10 ans",
            },
            main_goals: {
                legacy: "Anticipation de la succession",
                retirement: "Préparation de la retraite",
                taxes_optimisation: "Réduction de la pression fiscale",
                wealth_development: "Développer le patrimoine",
                new_incomes: "Obtention de revenus complémentaires"
            },
            // Bilan auto
            showDocModale: false,
            style: 'lbf',
            isLoading: false,
        }
    },
    watch: {
        'kyc_body.subject_of_recommandation': {
            handler(newValue, oldValue) {
                this.kyc_body[`${newValue}_goal`] = true
                this.kyc_body[`${oldValue}_goal`] = false
            }
        }
    },
    computed: {
        goals_without_subject() {
            return Object.keys(this.main_goals).filter(g => g != this.kyc_body.subject_of_recommandation)
        },
        nb_goals_selected() {
            let nb = 0

            Object.keys(this.main_goals).forEach(goal => {
                if (this.kyc_body[`${goal}_goal`] == true) {
                    nb ++
                }
            })

            return nb
        },
    },
    methods: {
        async createRecommendation() {
            await axios.post(this.$store.getters.get_api_url + 'clients/recommendation/', {
                clientId: this.$route.params.clientId,
                type: this.kyc_body.subject_of_recommandation
            }, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            this.$router.replace('/client/' + this.$route.params.clientId + '/view/')
        },
        toggleDocModale() {
            this.showDocModale = !this.showDocModale
        },
        async downloadDoc() {
            this.isLoading = true

            try {
                const res = await axios.post(this.$store.getters.get_api_url + 'services/audit', {
                    groupeCode : this.style,
                    clientId: this.$route.params.clientId,
                }, {
                    headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                })

                if (res.status == 200) {
                    const doc = await axios.get(this.$store.getters.get_api_url + 'documents/audit/Bilan-' + this.$route.params.clientId + '.docx', { 
                        responseType: 'blob',
                        headers: {
                            authorization : 'Bearer ' + this.$store.getters.get_token
                        }
                    })
                    const blob = new Blob([doc.data], { type: 'application/docx' })
                    const link = document.createElement('a')
                    link.href = URL.createObjectURL(blob)
                    link.download = 'Bilan.docx'
                    link.click()
                    URL.revokeObjectURL(link.href)
                }

                this.isLoading = false
            } catch (err) {
                console.log(err)
                this.isLoading = false
                alert('Erreur lors de la création du bilan automatisé')
            }

        },
        goToSimplifiedRecommendation() {
            this.$router.replace('recommendation')
        }

    }
}
</script>

<style scoped>

.survey-data-section {
    display: flex;
    justify-content: space-between;
    margin: 5px 10px;
}

.survey-data-section label, 
.survey-data-section input, 
.survey-data-section select {
    font-family: 'Avenir', sans-serif;
    flex-basis: 50%;
}

.survey-data-section input, 
.survey-data-section select {
    cursor: pointer;
    padding: 5px;
    font-size: 18px;
    border-radius: 5px;
    border: 1px solid lightgrey;
}

.actions-btns {
    margin: 40px 20px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.btn {
    font-family: "Avenir", sans-serif;
    cursor: pointer;
    margin: 5px auto;
    padding: 15px;
    font-weight: bold;
    text-align: center;
    border-radius: 5px;
    display: inline-block;
}
.btn-download {
    margin: 10px 40%;
    background-color: orange;
    color: white;
}
.btn-download:hover {
    background-color: orange;
}
.audit-call {
    background-color: orange;
    color: white;
}
.audit-call:hover {
    background-color: rgba(255, 166, 0, 0.676);
}

.advice-call {
    background-color: orange;
    color: white;
}
.advice-call:hover {
    background-color: rgba(255, 166, 0, 0.676);
}

.ip-call {
    background-color: #0a1c6b;
    color: white
}
.ip-call:hover {
    background-color: #0a1c6b79;
    color: white
}
</style>