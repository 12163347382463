<template>
    <div class="page-container">
        <div class="return-btn" @click="returnToClient">Retour au dossier client</div>

        <div class="section-container">
            <div class="actions-container">
                <h1>Solutions à étudier</h1>

                <p>À la vue de la situation de votre client, les solutions suivantes nous semblent pertinentes :</p>
                <ul>
                    <li>Vente d'un bien immobilier</li>
                    <li>Achat de parts de SCPI</li>
                    <li>Souscription d'un contrat d'assurance vie</li>
                    <li>Démembrement de la clause bénéficiaire</li>
                </ul>
                
                <div class="actions-list">

                    <h1>Actions préconisées</h1>

                    <div class="add-action" @click="toggleActionForm">Ajouter une action</div>

                    <div class="action" v-for="(action, index) in actionsList" :key="action._id">
                        <span  v-if="action.liquidities >= 0" class="material-symbols-outlined green-color">arrow_upward</span>
                        <span style="color: red" v-else class="material-symbols-outlined red-color">arrow_downward</span>

                        <div>{{ index + 1 }} - {{ $store.getters.codeTranslations[action.code] }}</div>
                        <div :class="{'green-color': action.liquidities >= 0, 'red-color': action.liquidities < 0}">{{ toEuro(action.liquidities) }}</div>
                    </div>

                    <p>Liquidités à réinvestir : <b>{{ toEuro(liquiditiesSum) }}</b></p>

                </div>
                
            </div>

            <div class="simulation">

                <p @click="toggleWealthDetail"><u>Voir le patrimoine</u></p>

                <div class="simulation-frame" @click="toggleSimulation">
                    <h3>Droits de succession</h3>
                    <h1>{{ toEuro(legacy.inheritance_cost) }}</h1>
                </div>

                <button @click="downloadDoc" v-if="!isLoading">Télécharger la recommandation</button>
                <loader-spin v-else/>
            </div>
        </div>

        <modale :show="showActionForm" @toggle="toggleActionForm">
            <AddActionForm @closeModale="toggleActionForm"/>
        </modale>

        <modale :show="showSimulation" @toggle="toggleSimulation" width='80%'>
            <LegacyResult/>
        </modale>

        <modale :show="showWealthDetail" @toggle="toggleWealthDetail" width='70%'>
            <WealthDetail/>
        </modale>
    </div>
</template>

<script>
import axios from 'axios'
import AddActionForm from './AddActionForm.vue'
import LegacyResult from '../../../../../results/Legacy.vue'
import WealthDetail from './WealthDetail.vue'

export default {
    components: {
        AddActionForm,
        LegacyResult,
        WealthDetail,
    },
    data() {
        return {
            recommendation: undefined,
            showActionForm: false,
            showSimulation: false,
            showWealthDetail: false,
            actionsList: [],
            isLoading: false,
            trad_sections: {
                '65ea20654c5c587685cc8743': 'family_protection_solutions',
                '65ea207d4c5c587685cc8749': 'wealth_changes_solutions',
                '65ea208c4c5c587685cc874f': 'legacy_solutions',
            },
            legacy_params: undefined,
            legacy: undefined,
        }
    },
    methods: {
        async getClientRecommendation() {
            const clientRecoRes = await axios.get(this.$store.getters.get_api_url + 'clients/find-client-recommendation/' + this.$route.params.clientId, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            this.recommendation = clientRecoRes.data
        },
        returnToClient() {
            this.$router.back()
        },
        toggleActionForm() {
            this.showActionForm = !this.showActionForm
            this.loadActions()
        },
        toggleSimulation() {
            this.showSimulation = !this.showSimulation
            this.getLegacyParams()
        },
        toggleWealthDetail() {
            this.showWealthDetail = !this.showWealthDetail
        },
        async loadActions() {
            const res = await axios.get(this.$store.getters.get_api_url + 'referential/actions/' + this.$route.params.scenarioId, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            this.actionsList = res.data
        },
        async getAutoReminders() {
            const wealthRes = await axios.get(this.$store.getters.get_api_url +'referential/wealth-reminders/' + this.$route.params.clientId, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            const wealth_audit_list = wealthRes.data

            const legacyRes = await axios.get(this.$store.getters.get_api_url +'referential/legacy-reminders/' + this.$route.params.clientId, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            const legacy_audit_list = legacyRes.data

            return {
                wealth_audit_list: wealth_audit_list,
                legacy_audit_list: legacy_audit_list,
                tax_audit_list: [],
            }
        },
        async getAutoSolutions() {
            const allSolutionsRes = await axios.get(this.$store.getters.get_api_url +'referential/solutions/', {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            const solutions = allSolutionsRes.data

            const solutionRes = await axios.get(this.$store.getters.get_api_url +'referential/recommendation-solutions/' + this.recommendation._id, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            let family_protection_solutions = []
            let wealth_changes_solutions = []
            let legacy_solutions = []

            for (const solutionId of solutionRes.data) {
                const solution = solutions.find(s => s._id == solutionId)

                if (this.trad_sections[solution.sectionId] == 'family_protection_solutions') {
                    family_protection_solutions.push(solutionId)

                } else if (this.trad_sections[solution.sectionId] == 'wealth_changes_solutions') {
                    wealth_changes_solutions.push(solutionId)

                } else if (this.trad_sections[solution.sectionId] == 'legacy_solutions') {
                    legacy_solutions.push(solutionId)

                } else {
                    legacy_solutions.push(solutionId)
                }
            }

            return {
                family_protection_solutions: family_protection_solutions,
                wealth_changes_solutions: wealth_changes_solutions,
                legacy_solutions: legacy_solutions,
            }
        },
        async downloadDoc() {
            this.isLoading = true

            const reminders = await this.getAutoReminders()
            const solutions = await this.getAutoSolutions()


            await axios.put(this.$store.getters.get_api_url +'clients/recommendation/', {
                ...this.recommendation,
                wealth_audit_list: reminders.wealth_audit_list,
                legacy_audit_list: reminders.legacy_audit_list,
                tax_audit_list : reminders.tax_audit_list,
                family_protection_solutions: solutions.family_protection_solutions,
                wealth_changes_solutions: solutions.wealth_changes_solutions,
                legacy_solutions: solutions.legacy_solutions,
                selectedScenario: this.$route.params.scenarioId,
            }, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            // Construction du document
            try {
                const res = await axios.post(this.$store.getters.get_api_url + 'services/recommendation', {
                    groupeCode : 'lcl',
                    clientId: this.$route.params.clientId,
                    recommendationId: this.recommendation._id,
                }, {
                    headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                })

                if (res.status == 200) {
                    const doc = await axios.get(this.$store.getters.get_api_url + 'documents/recommendation/Recommandation-' + this.$route.params.clientId + '.docx', { 
                        responseType: 'blob',
                        headers: {
                            authorization : 'Bearer ' + this.$store.getters.get_token
                        }
                    })
                    const blob = new Blob([doc.data], { type: 'application/docx' })
                    const link = document.createElement('a')
                    link.href = URL.createObjectURL(blob)
                    link.download = 'Recommandation.docx'
                    link.click()
                    URL.revokeObjectURL(link.href)
                }

                this.isLoading = false
            } catch (err) {
                console.log(err)
                this.isLoading = false
                alert('Erreur lors de la création du bilan automatisé')
            }
        },
        async getLegacyParams() {
            const res = await axios.get(this.$store.getters.get_api_url + 'clients/legacy-simulation/' + this.$route.params.scenarioId, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            this.legacy_params = res.data
        },
        async saveLegacyParams() {
            const body = {
                clientId       : this.$route.params.clientId,
                scenarioId     : this.$route.params.scenarioId,
                date           : undefined,
                order_before   : 1,
                choice_before  : "1/4PP",
                order_after    : 1,
                choice_after   : "1/4PP",
            }
            
            const res = await axios.post(this.$store.getters.get_api_url + 'clients/legacy-simulation', body, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            if (typeof res.data == 'string') {
                this.getLegacyParams()
            }
        },
        async computeLegacy() {
            try {
                const body = {
                    datas: this.$store.getters.legacyDatas(this.legacy_params.order_after, false),
                    spouseChoice: this.legacy_params.choice_after,
                    death_date: this.legacy_params.death_date,
                }
                
                const legacyRes = await axios.post(this.$store.getters.get_api_url + 'simulators/legacy', body, {
                    headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                })
                this.legacy = legacyRes.data

            } catch(err) {
                this.legacy = {}
            }
        },
    },
    computed: {
        liquiditiesSum() {
            return this.actionsList.map(a => a.liquidities).reduce((sum, current) => sum + current, 0);
        },
    },
    watch: {
        legacy_params: {
            deep: true, 
            handler() {
                this.computeLegacy()
            }
        },
        '$store.state': {
            deep: true,
            handler() {
                this.computeLegacy()
            }
        }
    },
    async beforeMount() {
        await this.getClientRecommendation()
        await this.$store.dispatch('importScenario', this.$route.params.scenarioId)
        this.loadActions()

        await this.getLegacyParams()

        if (this.legacy_params == undefined) {
            await this.saveLegacyParams()
        }
    }
}
</script>

<style scoped>
.page-container {
    position: relative;
    margin: 30px 100px;
}

.return-btn {
    cursor: pointer;
    position: absolute;
    padding: 15px;
    background-color: orange;
    font-weight: bold;
    color: white;
    border-radius: 5px;
}
.return-btn:hover{
    background-color: rgba(255, 166, 0, 0.659);
}

.section-container {
    padding: 70px 5%;
    display: flex;
    justify-content: space-between
}

.actions-container {
    flex-grow: 1;
    margin-right: 30px;
    padding: 30px;
    background-color: white;
    min-height: 60vh;
    border-radius: 10px;
}

.actions-list {
    position: relative;
}

.actions-list p {
    text-align: right;
}

.add-action {
    position: absolute;
    top: 0;
    right: 10px;
    padding: 10px;
    font-size: 14px;
    font-weight: bold;
    background-color: orange;
    border-radius: 10px;
}

.add-action:hover {
    cursor: pointer;
    background-color: #f3ebd9;
}

.action {
    position: relative;
    background-color: #f8f4eb;
    padding: 15px;
    padding-left: 60px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
    font-size: 20px;
}
.action > :nth-child(1) {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translate(0, -50%);
    font-size: 30px;
}
.green-color {
    color: green;
}
.red-color {
    color: red;
}
.action > :nth-child(2) {
    color: orange;
}

.simulation {
    background-color: #0a1c6b;
    flex-basis: 20%;
    min-width: 250px;
    border-radius: 10px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.simulation p {
    color: white;
    text-align: center;
}

.simulation-frame {
    cursor: pointer;
    background-color: white;
    padding: 10px;
}
.simulation-frame:hover {
    background-color: rgba(255, 255, 255, 0.782);
}

.simulation-frame h3 {
    text-align: center;
}

.simulation-frame h1 {
    text-align: center;
}

u:hover {
    cursor: pointer;
    color: orange;
}

button {
    cursor: pointer;
    padding: 15px;
    font-size: 20px;
}

</style>