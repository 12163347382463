<template>
    <div class="wrapper">
        <div class="item" @click="toggleShowText">
            <h1 v-if="type != 'section'">n°{{ item.number }} - {{ item.title }} - {{ item._id }}</h1>
            <h1 v-else>{{ item.title }} - {{ item._id }}</h1>
            <img class="icon" src="../assets/white-triangle.png">
        </div>

        <div class="text-frame" v-if="showText">
            <div class="formatted-html" v-html="item.text || item.introduction"></div>
            <div class="btn-frame">
                <div class="btn-modify" @click="toggleForm">Modifier</div>
            </div>
        </div>

        <modale :show="showForm" @toggle="toggleForm">
            <SolutionForm
                v-if="type=='solution'"
                :import="item"
                @closeModale="toggleForm"
            />

            <ReminderForm
                v-else-if="type == 'reminder'"
                :import="item"
                @closeModale="toggleForm"
            />

            <SectionForm
                v-else
                :import="item"
                @closeModale="toggleForm"
            />
        </modale>
    </div>
</template>

<script>
import SolutionForm from './SolutionForm.vue'
import ReminderForm from './ReminderForm.vue'
import SectionForm from './SectionForm.vue'

export default {
    props: ['item', 'type'],
    components: {
        SolutionForm,
        ReminderForm,
        SectionForm
    },
    data() {
        return {
            showText: false,
            showForm: false,
        }
    },
    methods: {
        toggleShowText() {
            this.showText = !this.showText
        },
        toggleForm() {
            this.showForm = !this.showForm
            this.$emit('toggleForm')
        },
    }
}
</script>

<style scoped>
.wrapper {
    margin: 5px 0;
}
.item {
    cursor: pointer;
    padding: 10px;
    background-color: rgb(0, 0, 99);
    color: white;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
h1 {
    margin: 0;
    font-size: 17px;
}

.icon {
    height: 15px;
    margin-right: 10px;
}

.text-frame {
    padding: 10px;
    border: 1px solid lightgrey;
    border-top: 0;
    background-color: white;
}

.btn-frame {
    text-align: right;
}

.btn-modify {
    cursor: pointer;
    padding: 10px;
    background-color: rgb(0, 0, 99);
    color: white;
    font-size: 15px;
    font-weight: bold;
    display: inline-block;
    align-self: right;
    border-radius: 5px;
}
.btn-modify:hover {
    background-color: rgb(1, 1, 122);
}
</style>


<style>
/* Styles injectés */
.formatted-html p {
    font-size: 11pt;
    color: black;
}

.formatted-html h1 {
    font-family: 'Montserrat Alternates Semibold'; 
    font-size: 32pt; 
    color: #1F2B3D; 
    margin-top: 7.5pt;
    margin-bottom: 7.5p;
}

.formatted-html h2 {
    font-family: 'Montserrat Alternates Semibold'; 
    font-size:24pt; 
    color: #1C2B3F;
    margin-top: 7.5p;
    margin-bottom: 7.5p;
}

.formatted-html h3 {
    font-family: 'Montserrat Alternates Semibold'; 
    font-size:18pt; 
    color: #0B1C6B;
    margin-top: 5pt;
    margin-bottom: 5pt;
}
.formatted-html h6 {
    font-family: 'Montserrat Alternates Semibold'; 
    font-size:16pt; 
    color: #0B1C6B;
    margin-top: 5pt;
    margin-bottom: 5pt;
}
.formatted-html strong {
    font-family: 'Montserrat Alternates Semibold'; 
    font-size:11pt; 
    color: #0B1C6B;
    margin: 0
}
</style>