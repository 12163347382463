<template>
   <div>
        <div class="menu">
            <div :class="{'menu-selection': !show_detailed_result}" @click="show_detailed_result=false">Données de simulation</div>
            <div :class="{'menu-selection': show_detailed_result, 'menu-disabled': computationResult == undefined}" @click="computationResult ? show_detailed_result=true : null">Résultat détaillé</div>
        </div>

        <div class="calculator-frame">

            <div class="left-section" v-if="!show_detailed_result">
                <div class="section-element">
                    <h3>Données de calcul</h3>

                    <div class="form-section">
                        <div class="input-frame">
                            <label>Montant emprunté</label>
                            <currency-input v-model="body.value"/>
                        </div>

                        <div class="input-frame">
                            <label>Mode de remboursement</label>
                            <select v-model="body.refund_type">
                                <option v-for="(value, key) in $store.getters.refundTypes" :key="key" :value="key">{{ value }}</option>
                            </select>
                        </div>
                    </div>

                    <div class="form-section">
                        <div class="input-frame">
                            <label>Taux d'emprunt</label>
                            <input type="number" v-model="body.rate"/>
                        </div>

                        <div class="input-frame">
                            <label>Taux d'assurance</label>
                            <input type="number" v-model="body.insurance_rate"/>
                        </div>
                    </div>

                    <div class="form-section">
                        <div class="input-frame">
                            <label>Durée de l'emprunt (en mois)</label>
                            <input type="number" v-model="duration"/>
                        </div>
                    </div>
                </div>

                <div class="section-element btn-section">
                    <div class="btn-send-computation btn-grow" @click="computeResult">Calculer</div>
                </div>
            </div>

            <div class="left-section" v-else>
                <div class="section-element">
                    <h3>Coût du crédit</h3>

                    <table>
                        <tr>
                            <th>Coût des intérêts</th>
                            <td>{{ toEuro(computationResult.interest_cost) }}</td>
                        </tr>

                        <tr>
                            <th>Coût de l'assurance</th>
                            <td>{{ toEuro(computationResult.insurance_cost) }}</td>
                        </tr>

                        <tr class="highlight">
                            <th>Coût total</th>
                            <td></td>
                        </tr>
                    </table>
                </div>

                <div class="section-element">
                    <h3>Tableau d'amortissement</h3>

                    <table class="calculation-result" v-if="computationResult != undefined">
                        <tr class="highlight">
                            <th>Mois</th>
                            <th>CRD début de période</th>
                            <th>Mensualité</th>
                            <th>Amortissement</th>
                            <th>Intérêts</th>
                            <th>Assurance</th>
                            <th>CRD fin de période</th>
                        </tr>

                        <tr v-for="(month, index) in computationResult.amortization_table" :key="index">
                            <td>{{ index + 1 }}</td>
                            <td>{{ toEuro(month.crd_before) }}</td>
                            <td>{{ toEuro(month.monthly_payment) }}</td>
                            <td>{{ toEuro(month.amortization) }}</td>
                            <td>{{ toEuro(month.interest) }}</td>
                            <td>{{ toEuro(month.insurance) }}</td>
                            <td>{{ toEuro(month.crd_after) }}</td>
                        </tr>
                    </table>
                </div>
            </div>

            <div class="right-section">
                <div class="section-element" v-if="!computationResult">
                    <h3>Résultats</h3>

                    <img src="../ressources/img/illustration.png"/>

                    <p>Lancez le calcul pour obtenir un résultat</p>
                </div>

                <div class="section-element" v-if="computationResult">
                    <h3>Mensualité d'emprunt</h3>
                    <h4>{{ toEuro(computationResult.amortization_table[0]?.monthly_payment) }}</h4>
                </div>

                <div class="section-element" v-if="computationResult">
                    <h3>Coût total du crédit</h3>
                    <h4>{{ toEuro(computationResult.total_loan_cost) }}</h4>
                </div>
            </div>
        </div> 
   </div>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            show_detailed_result: false, 

            duration: 240,
            body: {
                type: 'property_loan',
                value: 0,
                refund_type: "amortization",
                start_date: new Date(),
                end_date: undefined,
                rate: 4,
                insurance_rate: 0,
            },
            computationResult: undefined,
        }
    },
    watch: {
        duration() {
            let end_date = new Date()
            end_date.setMonth(end_date.getMonth() + this.duration)

            this.body.end_date = end_date
        },
    },
    methods: {
        async computeResult() {
            const res = await axios.post(this.$store.getters.get_api_url + 'simulators/loan-simulation', {
                ...this.body,
                rate: this.body.rate / 100,
                insurance_rate: this.body.insurance_rate / 100
            }, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            this.computationResult = res.data
        }
    }
}
</script>

<style src="./style.css" scoped></style>