<template>
    <tr class="list-element">
        <td v-if="!['family',].includes(type)">
            <div class="ip-creation" v-if="object.isIpCreation">IP</div>
            <div class="previous-creation" v-else>U</div>
        </td>
        <td v-if="['property', 'asset', 'movable'].includes(type)">{{ rightsLabel }}</td>
        <td>{{ mainLabel }}</td>
        <td>{{ secondLabel }}</td>
        <td v-if="type=='gift'">{{ toEuro(object.value) }}</td>
        <td v-if="type != 'will'">{{ thirdLabel }}</td>
        <td v-if="type=='gift'"><input type="checkbox" disabled :checked="object.is_shared"></td>

        <td class="btn-container" v-if="type != 'cca'">
            <edit-icon class="icon" @click="toggleModale"/>
            <delete-icon class="icon" @click="deleteElement" v-if="!['user', 'spouse'].includes(object.role)"/>

            <modale :show="showModale" @toggle="toggleModale">
                <Insurance :import="object" v-if="type == 'insurance'" @closeModale="toggleModale"/>
                <Property :import="object" v-else-if="type == 'property'" @closeModale="toggleModale"/>
                <Asset :import="object" v-else-if="type == 'asset'" @closeModale="toggleModale"/>
                <Society :import="object" v-else-if="type == 'society'" @closeModale="toggleModale"/>
                <Movable :import="object" v-else-if="type == 'movable'" @closeModale="toggleModale"/>
                <Debt :import="object" v-else-if="type == 'debt'" @closeModale="toggleModale"/>
                <Gift :import="object" v-else-if="type == 'gift'" @closeModale="toggleModale"/>
                <Will :import="object" v-else-if="type == 'will'" @closeModale="toggleModale"/>
                <Person :import="object" v-else-if="type == 'family'" @closeModale="toggleModale"/>
                <Income :import="object" v-else-if="type == 'income'" @closeModale="toggleModale"/>
                <TaxReduction :import="object" v-else-if="type == 'tax_reduction'" @closeModale="toggleModale"/>
                <DeductibleExpense :import="object" v-else-if="type == 'deductible_expense'" @closeModale="toggleModale"/>
                <Reward :import="object" v-else-if="type == 'reward'" @closeModale="toggleModale"/>
            </modale>
        </td>
    </tr>
</template>

<script>

import Insurance from '../forms/Insurance.vue'
import Property from '../forms/Property.vue'
import Asset from '../forms/Asset.vue'
import Society from '../forms/Society.vue'
import Movable from '../forms/Movable.vue'
import Debt from '../forms/Debt.vue'
import Gift from '../forms/Gift.vue'
import Will from '../forms/Will.vue'
import Person from '../forms/Person.vue'
import Income from '../forms/Income.vue'
import TaxReduction from '../forms/TaxReduction.vue'
import DeductibleExpense from '../forms/DeductibleExpense.vue'
import Reward from '../forms/Rewards.vue'
import { dateToString } from '../../assets/functions'

export default {
    components: {
        Insurance,
        Property,
        Asset,
        Society,
        Movable,
        Debt,
        Gift,
        Will,
        Person,
        Income,
        TaxReduction,
        DeductibleExpense,
        Reward,
    },
    props: ['object', 'type'],
    data() {
        return {         
            showModale: false,
        }
    },
    computed: {
        rightsLabel() {
            if (['property', 'asset', 'movable'].includes(this.type)) {
                if (this.object.detention.map(part => part.right).includes('pp')) {
                    return 'PP'

                } else if (this.object.detention.map(part => part.right).includes('np')) {
                    return 'NP'

                } else {
                    return 'US'
                }

            } else {
                return ''
            }
        },
        mainLabel() {
            if (this.type == "family") {
                // persons
                return this.capitalize(this.object.firstname) + ' ' + this.capitalize(this.object.lastname)

            } else if (this.type == 'society') {
                return this.object.type.toUpperCase() + ' - ' + this.object.label

            } else if (this.type == 'property') {
                return this.object.label || this.$store.getters.propertyTypes[this.object.category][this.object.type]

            } else if (this.type == 'asset') {
                return this.$store.getters.assetTypes[this.object.category][this.object.type]

            } else if (this.type == 'debt') {
                if (this.object.label && this.object.label != "") {
                    return this.object.label
                } else {
                    return this.$store.getters.debtTypes[this.object.type]
                }

            } else if (this.type == 'cca') {
                return "Compte courant d'associé"

            } else if (this.type == 'gift') {
                return `${this.$store.getters.giftTypes[this.object.type]} (${ dateToString(this.object.date) })`

            } else if (this.type == 'movable') {
                return this.$store.getters.movableTypes[this.object.type]

            } else if (this.type == 'insurance') {
                return this.object.label || this.$store.getters.insuranceTypes[this.object.type]

            } else if (this.type == 'income') {
                return this.$store.getters.incomeTypes[this.object.type]

            } else if (this.type == 'tax_reduction') {
                return this.$store.getters.reductionTypes[this.object.type]

            } else if (this.type == 'deductible_expense') {
                return this.$store.getters.deductionTypes[this.object.type]

            } else if (this.type == 'reward') {
                return this.$store.getters.rewardTypes[this.object.type]
            } else {
                // asset, movable, gifts, debts
                return this.capitalize(this.object.type)
            }
        },
        secondLabel() {
            if (this.type == "will") {
                return this.$store.getters.findOwnerName(this.object.owner)

            } else if (this.type == 'income') {
                return this.toEuro(this.object.net_value)

            } else if (this.type == 'tax_reduction') {
                return this.toEuro(this.object.value)

            } else if (this.type == 'deductible_expense') {
                return this.toEuro(this.object.value)

            } else if (this.type == 'debt') {
                return this.toEuro(this.object.remaining_value)

            } else if (['property', 'asset', 'cca', 'movable', 'insurance'].includes(this.type)) {
                return this.toEuro(this.object.value)

            } else if (this.type == 'family') {
                return this.$store.getters.allRoles[this.object.role]

            } else if (this.type == 'society') {
                const value = this.$store.getters.getSocietyValue(this.object._id)
                return this.toEuro(value)

            } else if (this.type == 'gift') {
                // gift
                if (this.object.donor2) {
                    return 'Couple pour ' + this.$store.getters.findOwnerName(this.object.donee)
                } else {
                    return this.$store.getters.findOwnerName(this.object.donor) + ' pour ' + this.$store.getters.findOwnerName(this.object.donee)
                }

            } else if (this.type == 'reward') {
                return this.$store.getters.getRewardDirectionLabel(this.object.direction) 

            } else {
                return ''
            }
        },
        thirdLabel() {
            if (['property', 'asset', 'movable', 'society'].includes(this.type)) {
                const owners = this.object.detention.map(part => part.owner_id)

                if (owners.includes(this.$store.getters.userId) && owners.includes(this.$store.getters.spouseId)) {
                    return 'Commun'

                } else if (owners.includes(this.$store.getters.userId)) {
                    return this.$store.getters.userName

                } else if (owners.includes(this.$store.getters.spouseId)) {
                    return this.$store.getters.spouseName

                } else {
                    return this.$store.getters.allOwnersObject[owners[0]]
                }

            } else if (this.type == 'gift') {
                return this.toEuro(this.object.fiscal_value, 0)

            } else if (['debt', 'insurance'].includes(this.type)) {
                if (this.object.owner2) {
                    return 'Commun'

                } else {
                    return this.$store.getters.allOwnersObject[this.object.owner1]
                }

            } else if (this.type == 'cca') {
                return this.$store.getters.allOwnersObject[this.object.owner1]

            } else if (this.type == 'family' && !['user', 'spouse'].includes(this.object.role)){
                if (this.object.parent2) {
                    return 'Commun'

                } else {
                    return this.$store.getters.findOwnerName(this.object.parent1)
                }

            } else if (this.type == 'family' && ['user', 'spouse'].includes(this.object.role)){
                return 'Membre du foyer'

            } else if (this.type == 'income') {
                return this.$store.getters.findOwnerName(this.object.person_id)
                
            } else if (this.type == 'tax_reduction') {
                return this.object.reduction_rate * 100 + ' %'
                
            } else if (this.type == 'reward') {
                return this.toEuro(this.object.value)

            } else {
                return ""
            }
        },
    },
    methods: {
        toggleModale() {
            this.showModale = !this.showModale
        },
        deleteElement() {
            if (this.type == 'property') {
                this.$store.dispatch('sell_property', this.object)

            } else if (this.type == 'asset') {
                this.$store.dispatch('sell_asset', this.object)

            } else if (this.type == 'movable') {
                this.$store.dispatch('sell_movable', this.object)
                
            } else if (this.type == 'insurance') {
                this.$store.dispatch('sell_insurance', this.object)
                
            } else if (this.type == 'society') {
                this.$store.dispatch('sell_society', this.object)
                
            } else if (this.type == 'debt') {
                this.$store.dispatch('refund_debt', this.object)
                
            } else if (this.type == 'gift') {
                this.$store.dispatch('delete_gift', this.object._id)
                
            } else if (this.type == 'will') {
                this.$store.dispatch('delete_will', this.object._id)
                
            } else if (this.type == 'income') {
                this.$store.dispatch('delete_income', this.object._id)
                
            } else if (this.type == 'tax_reduction') {
                this.$store.dispatch('delete_tax_reduction', this.object._id)
                
            } else if (this.type == 'deductible_expense') {
                this.$store.dispatch('delete_deductible_expense', this.object._id)
                
            } else if (this.type == 'family') {
                this.$store.dispatch('delete_person', this.object._id)

            } else if (this.type == 'reward') {
                this.$store.dispatch('delete_reward', this.object._id)

            }
        }
    },
}
</script>

<style scoped>

.btn-container {
    height: 100%;
    justify-content: center;
}

.icon {
    cursor: pointer;
}

.ip-creation, .previous-creation {
    position: relative;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}
.ip-creation {
    background-color: rgba(255, 221, 0, 0.915)
}
.previous-creation {
    background-color: rgba(1, 1, 117, 0.614);
    color: white;
}
</style>