<template>
    <div class="page">
        <SidePlaceholder class="side-menu"/>

        <div class="content">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import SidePlaceholder from './simulation/sections/SidePlaceholder.vue'
export default {
    components: {
        SidePlaceholder,
    }
}
</script>

<style scoped>
.side-menu {
    flex: 0 0 260px;
    width: 260px;
}

.page {
    display: flex;
    align-items: stretch;
    align-content: stretch;
    min-height: 70vw;
}
.content {
  flex-grow: 5;
}
</style>
