<template>
  <div class="loader">
    <img :src="logoUrl" alt="Logo" class="logo-spin"/>
  </div>
</template>

<script>
export default {
  name: 'Loader',
  data() {
    return {
      // Remplace par le chemin de ton logo
      logoUrl: require('../assets/logo-lbf.png') 
    }
  }
}
</script>

<style scoped>
.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7); /* Légèrement transparent */
  z-index: 9999; /* Pour s'assurer que le loader est au-dessus des autres éléments */
}

.logo-spin {
  width: 100px; /* Taille du logo */
  height: 100px;
  animation: spin 2s linear infinite; /* Animation de rotation */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
