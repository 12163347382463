<template>
    <div class="chart-container" v-if="chartData">
        <LineChart :chart-data="chartData" :chart-options="chartOptions"/>
    </div>
</template>

<script>

import { Line } from "vue-chartjs";
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    LineElement,
    PointElement,
    LinearScale,
    CategoryScale,
} from "chart.js";

// Enregistrer les composants nécessaires pour Chart.js
ChartJS.register(Title, Tooltip, Legend, LineElement, PointElement, LinearScale, CategoryScale);

export default {
    components: {
        LineChart: Line,
    },
    props: {
        labels: {
            type: Array,
            required: true,
        },
        datasets: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: true,
                        position: "top",
                    },
                    tooltip: {
                        callbacks: {
                            label: (tooltipItem) => {
                                const value = tooltipItem.raw;
                                return `${tooltipItem.label}: ${value}`;
                            },
                        },
                    },
                },
                scales: {
                    x: {
                        grid: {
                            color: "rgba(0, 0, 0, 0.1)", // Grille légère sur l'axe X
                        },
                    },
                    y: {
                        beginAtZero: true,
                        grid: {
                            color: "rgba(0, 0, 0, 0.1)", // Grille légère sur l'axe Y
                        },
                    },
                },
            },
        }
    },
    computed: {
        chartData() {
            if (this.labels && this.datasets) {
                return {
                    labels: this.labels,
                    datasets: this.datasets,
                }
            } else {
                return undefined
            }
        }
    },

}
</script>

<style scoped>
.chart-container {
    width: 100%;
    height: 400px;
}
#line-chart {
    width: 100%;
}
</style>
  