<template>
    <div class="list-section">
        <h1 class="section-title">
            Contrats d'assurance - {{ toEuro($store.getters.getTotalInsuranceWealth) }}

            <add-btn type="insurance"></add-btn>
        </h1>

        <table class="table-elements">
            <list-component class="list-element"
                v-for="insurance in list_assets"
                :key="insurance._id"
                :object="insurance"
                type="insurance"
            />
        </table>
    </div>
</template>

<script>

export default {
    data() {
        return {}   
    },
    computed: {
        list_assets() {
            if (this.$store.getters.get_selected_vue == 'all-wealth' || this.$store.getters.get_selected_vue == 'family-wealth') {
                return this.$store.getters.getAllInsurances
                
            } else {
                // Les sociétés ne peuvent pas souscrire à des assurances vie
                return []
            }
        },
    }
}
</script>