<template>
   <div>
        <div class="menu">
            <div :class="{'menu-selection': !show_detailed_result}" @click="show_detailed_result=false">Données de simulation</div>
            <div :class="{'menu-selection': show_detailed_result, 'menu-disabled': computationResult == undefined}" @click="computationResult ? show_detailed_result=true : null">Résultat détaillé</div>
        </div>
        
        <div class="calculator-frame">
            <div class="left-section" v-if="!show_detailed_result">
                <div class="section-element">
                    <h3>Société</h3>

                    <div class="form-section">
                        <div class="input-frame">
                            <label>Date de création de la société</label>
                            <input type="date" v-model="body.creation_date">
                        </div>

                        <div class="input-frame">
                            <label>Nombre de titres sociaux emis par la société au total</label>
                            <input type="number" v-model="body.nb_shares_total">
                        </div>
                    </div>

                    <div class="form-section">
                        <div class="input-frame">
                            <label>Activité commerciale, artisanale, libérale, agricole ou de holding animatrice</label>
                            <select v-model="body.is_eligible_activity">
                                <option :value="true">OUI</option>
                                <option :value="false">NON</option>
                            </select>
                        </div>
                    </div>

                    <div class="form-section">
                        <div class="input-frame">
                            <label>Valeur totale des titres de la société</label>
                            <currency-input v-model="body.total_value"/>
                        </div> 

                        <div class="input-frame">
                            <label>La société est une PME</label>
                            <select v-model="body.is_pme">
                                <option :value="true">OUI</option>
                                <option :value="false">NON</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="section-element">
                    <h3>Titres détenus</h3>

                    <div class="form-section">
                        <div class="input-frame">
                            <label>Les titres sont identifiables individuellement (par exemple, ils sont numérotés dans un registre tenu par la société)</label>
                            <select v-model="body.individually_identifiable">
                                <option :value="true">OUI</option>
                                <option :value="false">NON</option>
                            </select>
                        </div> 
                    </div>

                    <table>
                        <tr class="highlight">
                            <th>Date d'acquisition</th>
                            <th>Nombre de titres achetés</th>
                            <th>Prix d'achat total</th>
                            <th v-if="body.individually_identifiable">Nombre de ces titres en vente</th>
                        </tr>

                        <tr v-for="(acquisition, index) in body.acquisitions" :key="index">
                            <td> <input type="date" v-model="acquisition.date"> </td>
                            <td> <input type="number" v-model="acquisition.nb_shares"> </td>
                            <td> <currency-input v-model="acquisition.price"/> </td>
                            <td v-if="body.individually_identifiable"> <input type="number" v-model="acquisition.nb_sold"> </td>
                        </tr>

                        <tr>
                            <td :colspan="body.individually_identifiable ? 4 : 3" class="btn" @click="addAcquisition">AJOUTER UNE ACQUISITION</td>
                        </tr>
                    </table>

                    <div class="form-section" v-if="!body.individually_identifiable">
                        <div class="input-frame">
                            <label>Nombre de titres vendus</label>
                            <input type="number" v-model="body.nb_sold">
                        </div> 
                    </div>
                </div>

                <div class="section-element">
                    <h3>Fiscalité</h3>

                    <div class="form-section">
                        <div class="input-frame">
                            <label>Date de cession</label>
                            <input type="date" v-model="body.selling_date">
                        </div> 
                    </div>

                    <div class="form-section">
                        <div class="input-frame">
                            <label>Option pour le barème progressif de l'impôt sur le revenu</label>
                            <select v-model="body.is_progressive_tax_scale">
                                <option :value="true">OUI</option>
                                <option :value="false">NON</option>
                            </select>
                        </div>

                        <div class="input-frame" v-if="body.is_progressive_tax_scale">
                            <label>Système du quotient</label>
                            <select v-model="body.has_quotient_system">
                                <option :value="true">OUI</option>
                                <option :value="false">NON</option>
                            </select>
                        </div>
                    </div>

                    <div class="form-section">
                        <div class="input-frame">
                            <label>Le vendeur est le dirigeant est est en retraite depuis moins de 2 ans ou part en retraite dans les 2 ans qui suivent la cession.</label>
                            <select v-model="body.is_retirement">
                                <option :value="true">OUI</option>
                                <option :value="false">NON</option>
                            </select>
                        </div>
                    </div>

                    <table>
                        <tr class="highlight">
                            <th>Type de revenu</th>
                            <th>Montant</th>
                        </tr>

                        <tr v-for="(income, index) in body.taxes_datas.incomes" :key="index">
                            <td>
                                <select v-model="income.type">
                                    <option v-for="(value, key) in $store.getters.incomeTypes" :value="key" :key="key">{{ value }}</option>
                                </select>
                            </td>

                            <td>
                                <currency-input v-model="income.net_value"/>
                            </td>
                        </tr>
                        
                        <tr class="highlight">
                            <td colspan="2" class="btn" @click="addIncome">AJOUTER UN REVENU</td>
                        </tr>
                    </table>
                </div>

                <div class="section-element">
                    <h3>Situation familiale</h3>

                    <table>
                        <tr class="highlight">
                            <th style="min-width: 80px;">Role</th>
                            <th>Date de naissance</th>
                            <th>Rattaché au foyer</th>
                            <th>Hebergé</th>
                            <th>Étudiant</th>
                            <th>Niveau scolaire</th>
                            <th>Garde alternée</th>
                            <th>Situation familiale</th>
                        </tr>

                        <tr v-for="(person, index) in body.taxes_datas.persons" :key="index">
                            <td>
                                <select v-model="person.role">
                                    <option v-for="(value, key) in $store.getters.allRoles" :value="key" :key="key">{{ value }}</option>
                                </select>
                            </td>

                            <td>
                                <input type="date" v-model="person.birthdate">
                            </td>

                            <td>
                                <select v-model="person.is_attached" v-if="person.role == 'child'">
                                    <option :value="true">OUI</option>
                                    <option :value="false">NON</option>
                                </select>
                            </td>

                            <td>
                                <select v-model="person.lives_at_home" v-if="person.role == 'child' && !person.is_attached">
                                    <option :value="true">OUI</option>
                                    <option :value="false">NON</option>
                                </select>
                            </td>

                            <td>
                                <select v-model="person.is_student" v-if="person.role == 'child'">
                                    <option :value="true">OUI</option>
                                    <option :value="false">NON</option>
                                </select>
                            </td>

                            <td>
                                <select v-model="person.education_level" v-if="person.role == 'child' && person.is_student">
                                    <option v-for="(value, key) in $store.getters.eductionLevels" :value="key" :key="key">{{ value }}</option>
                                </select>
                            </td>

                            <td>
                                <select v-model="person.joint_custody" v-if="person.role == 'child'">
                                    <option :value="true">OUI</option>
                                    <option :value="false">NON</option>
                                </select>
                            </td>

                            <td>
                                <select v-model="person.situation" v-if="person.role == 'child'">
                                    <option v-for="(value, key) in $store.getters.maritalSituations" :value="key" :key="key">{{ value }}</option>
                                </select>
                            </td>
                        </tr>
                        
                        <tr class="highlight">
                            <td colspan="8" class="btn" @click="addPerson">AJOUTER UNE PERSONNE</td>
                        </tr>
                    </table>

                </div>

                <div class="section-element btn-section">
                    <div class="btn-send-computation btn-grow" @click="computeResult">Calculer</div>
                </div>
            </div>

            <div class="left-section" v-else>
                <div class="section-element">
                    <h3>Compartiments de détention</h3>

                    <table>
                        <tr class="highlight">
                            <th>N°</th>
                            <th>Date d'acquisition</th>
                            <th>Nombre de titres vendus</th>
                            <th>Durée de détention</th>
                            <th>Prix d'achat unitaire</th>
                            <th>Prix de vente unitaire</th>
                        </tr>

                        <tr v-for="(compartment, index) in computationResult.capital_gain_result.compartments_by_detention_time" :key="index">
                            <td>{{ index + 1 }}</td>
                            <td>{{ dateToString(compartment.date) }}</td>
                            <td>{{ compartment.nb_sold }}</td>
                            <td>{{ compartment.detention_length }} ans</td>
                            <td>{{ toEuro(compartment.unit_price) }}</td>
                            <td>{{ toEuro(compartment.unit_value) }}</td>
                        </tr>
                    </table>
                </div>

                <div class="section-element" v-for="(compartment, index) in computationResult.taxable_base_results" :key="index">
                    <h3>Compartiment {{ index + 1 }}</h3>

                    <h5 v-if="compartment.reduction_type">Ce compartiment de plus-value est éligible à l'{{compartment.reduction_type.toLowerCase()}} de {{ compartment.reduction }}.</h5>
                    <h5 v-else>Ce compartiment n'est éligible à aucune réduction de l'assiette imposable.</h5>
                    <h5>Les données de calcul de l'impôt sur la plus-value sont donc les suivantes : </h5>

                    <table>
                        <tr>
                            <th>Assiette de l'impôt sur la plus-value</th>
                            <td>{{ toEuro(compartment.tax_base) }}</td>
                        </tr>

                        <tr>
                            <th>Assiette des prélèvements sociaux</th>
                            <td>{{ toEuro(compartment.social_tax_base) }}</td>
                        </tr>

                        <tr>
                            <th>Assiette de calcul de la CSG déductible</th>
                            <td>{{ toEuro(compartment.deductible_csg_base) }}</td>
                        </tr>
                    </table>
                </div>

                <div class="section-element">
                    <h3>Calcul de l'imposition</h3>

                    <h5>Assiette de calcul de l'impôt sur le revenu : {{ toEuro(computationResult.total_tax_base) }}</h5>
                    <h5>Assiette de calcul des prélèvements sociaux : {{ toEuro(computationResult.total_social_tax_base) }}</h5>
                    <h5>Assiette de calcul de la CSG déductible : {{ toEuro(computationResult.total_deductible_csg_base) }}</h5>

                    <table>
                        <tr>
                            <th>Impôt sur le revenu</th>
                            <td>{{ toEuro(computationResult.taxes_result.taxes) }}</td>
                        </tr>

                        <tr>
                            <th>Prélèvement sociaux</th>
                            <td>{{ toEuro(computationResult.taxes_result.social_taxes) }}</td>
                        </tr>

                        <tr class="highlight">
                            <th>Total</th>
                            <td>{{ toEuro(computationResult.taxes_result.total) }}</td>
                        </tr>
                    </table>
                </div>
            </div>

            <div class="right-section">
                <div class="section-element" v-if="!computationResult">
                    <h3>Résultats</h3>

                    <img src="../ressources/img/illustration.png"/>

                    <p>Lancez le calcul pour obtenir un résultat</p>
                </div>

                <div class="section-element" v-if="computationResult">
                    <h3>Impôt total</h3>
                    <h4>{{ toEuro( computationResult.taxes_result.total ) }}</h4>
                </div>

            </div>
        </div>
   </div>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            show_detailed_result: false,
            computationResult: undefined,
            body: {
                is_eligible_activity: true,
                is_pme: false,
                creation_date: "1998-01-22",
                selling_date: "",
                nb_shares_total: 200,
                total_value: 10000000,
                is_progressive_tax_scale: true,
                has_quotient_system: true,
                is_retirement: false, 
                individually_identifiable: true,
                nb_sold: 100,
                acquisitions: [],
                taxes_datas: {
                    couple: {
                        situation: 'alone',
                    },
                    persons: [
                        { _id: 1, role: 'user', birthdate: '1978-01-01' },
                        
                    ],
                    incomes: [],
                    properties: [], 
                    assets: [],
                    societies: [],
                    tax_reductions: [],
                    deductible_expenses: []
                }
            }
        }
    },
    methods: {
        addAcquisition() {
            this.body.acquisitions.push({
                date: "",
                nb_shares: 0, 
                price: 0,
                nb_sold: 0,
            })
        },
        addPerson() {
            const all_ids = this.body.taxes_datas.persons.map(p => p._id)
            const max_id = Math.max(...all_ids)

            this.body.taxes_datas.persons.push({
                _id: max_id + 1,
                role: 'child',
                birthdate: "1990-01-01",
                lives_at_home: true,
                is_student: false, 
                education_level: undefined,
                is_attached: true,
                joint_custody: false,
                situation: "alone",
            })
        },
        addIncome() {
            const all_ids = this.body.taxes_datas.incomes.map(p => p._id)  
            const max_id = all_ids.length == 0 ? 0 : Math.max(...all_ids)

            this.body.taxes_datas.incomes.push({
                _id: max_id + 1,
                type: 'salary',
                net_value: 0, 
                person_id: 1,
                isMicro: true,
            })
        },
        async computeResult() {
            this.body.taxes_datas.couple.situation = this.situation

            const res = await axios.post(this.$store.getters.get_api_url + 'simulators/society-sell', this.body, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            this.computationResult = res.data
        }
    },
    computed: {
        situation() {
            const used_roles = this.body.taxes_datas.persons.map(p => p.role)

            if (used_roles.includes('spouse')) {
                return "married"
            } else {
                return "alone"
            }
        }
    },
    mounted() {
        const today = new Date();
        const formattedDate = today.getFullYear() + '-' + 
                      String(today.getMonth() + 1).padStart(2, '0') + '-' + 
                      String(today.getDate()).padStart(2, '0');

        this.body.selling_date = formattedDate
    }
}
</script>

<style src="./style.css" scoped></style>