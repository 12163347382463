<template>
    <div class="list-section">
        <h1 class="section-title">
            Patrimoine immobilier - {{ toEuro($store.getters.getTotalPropertyWealth) }}

            <add-btn type="property"></add-btn>
        </h1>

        <table class="table-elements">
            <list-component class="list-element"
                v-for="property in list_assets"
                :key="property._id"
                :object="property"
                type="property"
            />
        </table>
    </div>
</template>

<script>

export default {
    data() {
        return {}
    },
    computed: {
        list_assets() {
            const allAssets = this.$store.getters.getAllProperties

            if (this.$store.getters.get_selected_vue == 'all-wealth') {
                return allAssets
            } else if (this.$store.getters.get_selected_vue == 'family-wealth') {
                const society_ids = this.$store.getters.getAllSocieties.map(s => s._id)
                return allAssets.filter(asset => {
                    // Vérifie si tous les owner_id sont dans la liste ids
                    const allOwnersInIds = asset.detention.every(owner => society_ids.includes(owner.owner_id));
                    // Conserve le bien si ce n'est pas le cas
                    return !allOwnersInIds;
                });
                
            } else {
                // Patrimoine d'une société
                return allAssets.filter( a => a.detention.map(d => d.owner_id).includes(this.$store.getters.get_selected_vue) )
            }
        },
    },
}
</script>

<style scoped>
.funding-section {
    display: flex;
    flex-direction: column;
    font-size: 20px;
}
.loan-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
}
</style>