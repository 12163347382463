<template>
    <form class="add-form">
        <div class="form-section">
            <label for="donor">Donateur</label>
            <select id="donor" v-model="donor" :disabled="!isOriginalScenario && !isNew">
                <option v-for="(value, index) in $store.getters.findDonors" :key="index" :value="value">{{ $store.getters.findOwnerName(value) }}</option>
            </select>
        </div>

        <div class="form-section">
            <label for="type">Type de donation</label>
            <select id="type" v-model="body.type" :disabled="!isOriginalScenario && !isNew">
                <option v-for="(value, key) in $store.getters.giftTypes" :key="key" :value="key">{{ value }}</option>
            </select>
        </div>

        <div class="form-section" v-if="hasAssetSelection && isNew">
            <label for="property">Actif à donner</label>
            <select v-model="given_asset" id="property">
                <option v-for="asset in givable_list" :key="asset._id" :value="asset">
                    {{ asset.type }} {{ asset.label }}  - {{ toEuro(asset.value) }}
                </option>
            </select>
        </div>

        <div class="form-section">
            <label for="value">Donation-partage</label>
            <input type="checkbox" v-model="body.is_shared">
        </div>

        <div class="form-section">
            <label for="value">Donation en nue-propriété</label>
            <input type="checkbox" v-model="body.is_dismembered">
        </div>

        <div class="form-section" v-if="!isNew">
            <label for="value">Date</label>
            <input type="date" v-model="body.date">
        </div>

        <div class="form-section" v-if="isNew">
            <label>Bénéficiaire(s)</label>
            <div class="multi-select">

                <multi-select
                    :elements="$store.getters.getAllInheritors"
                    title="Selectionnez les bénéficiaires" 
                    label="firstname"
                    :values="donees"
                    @closed="(result)=>{donees = result}"
                />
            </div>
        </div>

        <div class="form-section" v-else>
            <label for="value">Donataire</label>
            <select v-model="body.donee" :disabled="!body.is_shared">
                <option v-for="donee in $store.getters.getAllInheritors" :key="donee._id" :value="donee._id">{{ donee.firstname }} {{ donee.lastname }}</option>
            </select>
        </div>

        <div class="form-section" v-if="!isNew || !hasAssetSelection">
            <label for="value">Valeur</label>
            <currency-input v-model="body.value"/>
        </div>

        <div class="form-section" v-if="!isNew">
            <label for="fiscal_value">Valeur actuelle du bien donné</label>
            <currency-input v-model="body.current_value"/>
        </div>

        <div class="form-section" v-if="!isNew">
            <label for="fiscal_value">Valeur fiscale</label>
            <currency-input v-model="body.fiscal_value"/>
        </div>

        <div class="form-section">
            <label for="value">Avancement de part successorale</label>
            <input type="checkbox" v-model="body.aps">
        </div>

        <div class="btn-submit grow-effect" @click="submit()">Enregistrer</div>
        <p class="errorMsg">{{ errorMsg }}</p>
    </form>
</template>

<script>

export default {
    props: ['import'],
    data() {
        return {
            isNew: true,
            donor: this.$store.getters.userId,
            errorMsg: "",
            given_asset: {},
            donees: [],
            body: {
                _id: undefined,
                clientId: undefined,
                scenarioId: undefined,
                donor: undefined,
                donee: undefined,
                type: "property",
                value: 0,
                fiscal_value: 0,
                current_value: 0,
                date: '',
                aps: true,
                given_asset: undefined,
                is_shared: false,
                is_dismembered: false,
                isIpCreation: false
            },
        }
    },
    methods: {
        computeValue() {
            this.body.value = this.given_asset.value
        },
        addGift(donor_id) {
            if (this.hasAssetSelection) {
                const pp_parts = this.given_asset.detention.filter(part => part.owner_id == donor_id)
                
                let pp_value = 0
                if (this.body.type == 'dutreil' || this.body.type == 'society') {
                    const value = this.$store.getters.getSocietyValue(this.given_asset._id)
                    pp_value = pp_parts.map(part => part.part * value).reduce((sum, current) => sum + current, 0);
                } else {
                    pp_value = pp_parts.map(part => part.part * this.given_asset.value).reduce((sum, current) => sum + current, 0);
                }
                

                let newGiftsList = []
                this.donees.forEach(donee_id => {
                    let newGift            = JSON.parse(JSON.stringify(this.body))
                    newGift._id            = this.getRandomId()
                    newGift.donor          = donor_id
                    newGift.donee          = donee_id
                    newGift.date           = this.getCurrentDate()
                    newGift.value          = pp_value / this.donees.length
                    newGift.current_value  = pp_value / this.donees.length
                    
                    if (this.body.is_dismembered) {
                        const np_value = 1 - this.$store.getters.usValue(donor_id)
                        newGift.fiscal_value = np_value * newGift.value
                    } else {
                        newGift.fiscal_value = newGift.value
                    }

                    newGiftsList.push(newGift)
                })

                return newGiftsList

            } else {
                let given_value = this.body.value
                if (this.donor == 'commun') {
                    given_value = given_value / 2
                }

                let newGiftsList = []
                this.donees.forEach(donee_id => {
                    let newGift           = JSON.parse(JSON.stringify(this.body))
                    newGift.date          = this.getCurrentDate()
                    newGift.donor         = donor_id
                    newGift.donee         = donee_id
                    newGift.value         = given_value / this.donees.length
                    newGift.current_value = newGift.value
                    newGift.fiscal_value  = newGift.value

                    newGiftsList.push(newGift)
                })

                return newGiftsList
            }
        },
        async submit() {
            if (this.isOriginalScenario) {
                this.body.isIpCreation = false
            } else if (this.isNew) {
                this.body.isIpCreation = true
            }
            
            if (this.body.type == undefined) {
                this.errorMsg = "Précisez le type de transmission"
                return;
            } else if (this.body.fiscal_value <= 0 && this.body.value <= 0 && this.given_asset == {}) {
                this.errorMsg = "Renseignez une valeur supérieure à 0€"
                return;
            } else if (!this.donor) {
                this.errorMsg = "Précisez le donateur"
                return;
            } else if (this.donees.length == 0 && !this.body.donee) {
                this.errorMsg = "Séléctionnez au moins un donataire"
                return;

            } else {
                if (this.isNew) {
                    let newGifts = []

                    for (let i = 0; i < this.donors.length; i++) {
                        newGifts = this.addGift(this.donors[i])

                        await this.$store.dispatch('create_gift_list', {
                            gifts_list   : newGifts,
                            asset_id : this.given_asset?._id
                        })
                    }

                } else {
                    this.$store.dispatch('action_modify_gift', this.body)
                }

                this.$emit('closeModale')
            }
            
        },
    },
    watch: {
        donor() {
            if (this.donor == 'commun') {
                // Impossible lors d'une modification de changer un donateur pour commun
                this.body.donor = this.$store.getters.userId
            } else {
                this.body.donor = this.donor
            }
        },
        given_right() {
            this.computeValue()
        },
        given_asset() {
            this.body.given_asset = this.given_asset._id
            this.computeValue()
        },
    },
    computed: {
        isOriginalScenario() {
            const routeArray = this.$route.fullPath.split('/')

            if (routeArray[3] == 'profile' || this.$route.query.isOriginal) {
                return true
            } else {
                return false
            }
        },
        hasAssetSelection() {
            const require_selection_types = ['property', 'financial', 'movable', 'dutreil', 'society']

            if (require_selection_types.includes(this.body.type)) {
                return true
            } else {
                return false
            }
        },
        rights() {
            if (this.given_asset.rights == 'pp')
                return { pp: 'Pleine propriété', np: 'Nue-propriété' }
            else
                return {}
        },
        donors() {
            if (this.donor == 'commun'){
                return [this.$store.getters.userId, this.$store.getters.spouseId]

            } else {
                return [this.donor]
            }
        },
        givable_properties() {
            let list = []
            if (this.donor == 'commun') {

                list = this.$store.getters.getAllProperties.filter(property => {
                    const owners = property.detention.filter(part => part.right == 'pp').map(part => part.owner_id)
                    if (owners.includes(this.$store.getters.userId) && owners.includes(this.$store.getters.spouseId)) {
                        return true
                    } else {
                        return false
                    }
                })

            } else if (this.donor == this.$store.getters.userId) {
                list = this.$store.getters.getAllProperties.filter(property => {
                    const owners = property.detention.filter(part => part.right == 'pp').map(part => part.owner_id)
                    if (owners.includes(this.$store.getters.userId)) {
                        return true
                    } else {
                        return false
                    }
                })

            } else {
                list = this.$store.getters.getAllProperties.filter(property => {
                    const owners = property.detention.filter(part => part.right == 'pp').map(part => part.owner_id)
                    if (owners.includes(this.$store.getters.spouseId)) {
                        return true
                    } else {
                        return false
                    }
                })
            }

            return list.map(a => {
                let copy = JSON.parse(JSON.stringify(a))
                copy.type = this.$store.getters.propertyTypes[a.category][a.type]
                return copy
            })
        },
        givable_assets() {
            if (this.donor == 'commun') {
                return this.$store.getters.getAllAssets.filter(property => {
                    const owners = property.detention.filter(part => part.right == 'pp').map(part => part.owner_id)
                    if (owners.includes(this.$store.getters.userId) && owners.includes(this.$store.getters.spouseId)) {
                        return true
                    } else {
                        return false
                    }
                })

            } else if (this.donor == this.$store.getters.userId) {
                return this.$store.getters.getAllAssets.filter(property => {
                    const owners = property.detention.filter(part => part.right == 'pp').map(part => part.owner_id)
                    if (owners.includes(this.$store.getters.userId)) {
                        return true
                    } else {
                        return false
                    }
                })

            } else {
                return this.$store.getters.getAllAssets.filter(property => {
                    const owners = property.detention.filter(part => part.right == 'pp').map(part => part.owner_id)
                    if (owners.includes(this.$store.getters.spouseId)) {
                        return true
                    } else {
                        return false
                    }
                })
            }
        },
        givable_societies() {
            let list = []
            if (this.donor == 'commun') {
                list = this.$store.getters.getAllSocieties.filter(property => {
                    const owners = property.detention.filter(part => part.right == 'pp').map(part => part.owner_id)
                    if (owners.includes(this.$store.getters.userId) && owners.includes(this.$store.getters.spouseId)) {
                        return true
                    } else {
                        return false
                    }
                })

            } else if (this.donor == this.$store.getters.userId) {
                list = this.$store.getters.getAllSocieties.filter(property => {
                    const owners = property.detention.filter(part => part.right == 'pp').map(part => part.owner_id)
                    if (owners.includes(this.$store.getters.userId)) {
                        return true
                    } else {
                        return false
                    }
                })

            } else {
                list = this.$store.getters.getAllSocieties.filter(property => {
                    const owners = property.detention.filter(part => part.right == 'pp').map(part => part.owner_id)
                    if (owners.includes(this.$store.getters.spouseId)) {
                        return true
                    } else {
                        return false
                    }
                })
            }

            return list.map(a => {
                let copy = JSON.parse(JSON.stringify(a))
                copy.type = this.$store.getters.societyTypes[a.type]
                copy.value = this.$store.getters.getSocietyValue(a._id)
                return copy
            })
        },
        givable_movable() {
            if (this.donor == 'commun') {
                return this.$store.getters.getAllMovable.filter(property => {
                    const owners = property.detention.filter(part => part.right == 'pp').map(part => part.owner_id)
                    if (owners.includes(this.$store.getters.userId) && owners.includes(this.$store.getters.spouseId)) {
                        return true
                    } else {
                        return false
                    }
                })

            } else if (this.donor == this.$store.getters.userId) {
                return this.$store.getters.getAllMovable.filter(property => {
                    const owners = property.detention.filter(part => part.right == 'pp').map(part => part.owner_id)
                    if (owners.includes(this.$store.getters.userId)) {
                        return true
                    } else {
                        return false
                    }
                })

            } else {
                return this.$store.getters.getAllMovable.filter(property => {
                    const owners = property.detention.filter(part => part.right == 'pp').map(part => part.owner_id)
                    if (owners.includes(this.$store.getters.spouseId)) {
                        return true
                    } else {
                        return false
                    }
                })
            }
        },
        givable_list() {
            if (this.body.type == 'property') {
                return this.givable_properties
            } else if (this.body.type == 'financial') {
                return this.givable_assets
            } else if (this.body.type == 'movable') {
                return this.givable_movable
            } else if (this.body.type == 'society' || this.body.type == 'dutreil') {
                return this.givable_societies
            } else {
                return []
            }
            
        },
    },
    async mounted() {
        

        if (this.import) {
            this.isNew = false
            this.body = JSON.parse(JSON.stringify(this.import))
            this.body.date = this.body.date?.slice(0,10)

            this.donor = this.body.donor
        }

        if (this.isNew) {
            this.body.clientId = this.$route.params.clientId
            this.body.scenarioId = this.$route.params.scenarioId
        }
    },
}
</script>

<style scoped>
.multi-select {
    display: block;
    width: 45%;
}
</style>

<style src="./style.css" scoped></style>