<template>
    <div>
        <div class="menu">
            <div :class="{'menu-selection': !show_detailed_result}" @click="show_detailed_result=false">Données de simulation</div>
            <div :class="{'menu-selection': show_detailed_result, 'menu-disabled': computationResult == undefined}" @click="computationResult ? show_detailed_result=true : null">Résultat détaillé</div>
        </div>

        <div class="calculator-frame">
            <div class="left-section" v-if="!show_detailed_result">
                <div class="section-element">
                    <div class="ris-validation-box">
                        <span class="valid-indicator" v-if="base_64_ris_validation_result?.result == 'ok'"><i class="material-icons">check_circle</i></span>
                        <span class="invalid-indicator" v-else-if="base_64_ris_validation_result != undefined"><i class="material-icons">cancel</i>{{ base_64_ris_validation_result?.message }}</span>
                        
                    </div>

                    <h3>Dépôt du RIS au format PDF</h3>
                    <loader-spin v-if="validation_loading"></loader-spin>
                    <file-drop @file-dropped="handleFileDrop" v-else></file-drop>
                </div>

                <div class="section-element">
                    <h3>Informations complémentaires</h3>

                    <div class="form-section">
                        <div class="input-frame">
                            <label>Sexe</label>
                            <select v-model="body.sexe">
                                <option value="H">Homme</option>
                                <option value="F">Femme</option>
                            </select>
                        </div>

                        <div class="input-frame">
                            <label>Nombre d'enfants</label>
                            <input type="number" v-model="body.nb_enfants">
                        </div>
                    </div>

                    <div class="form-section">
                        <div class="input-frame">
                            <label>Date de naissance</label>
                            <input type="date" v-model="birthdate">
                        </div>
                    </div>

                    <div class="form-section">
                        <div class="input-frame">
                            <label>Revenu d'activité brut annuel</label>
                            <currency-input v-model="body.revenu"></currency-input>
                        </div>
                    </div>
                </div>

                <div class="section-element btn-section">
                    <loader-spin v-if="computation_loading"></loader-spin>
                    <div class="btn-send-computation btn-grow" @click="computeResult" v-else>Calculer</div>
                </div>
            </div>

            <div class="left-section" v-if="show_detailed_result">
                <div class="section-element">
                    <table class="calculation-result">
                        <tr>
                            <th>Date de début de carrière</th>
                            
                            <td>{{ computationResult.entete.dt_debut_carriere }}</td>
                        </tr>

                        <tr>
                            <th>Carrière longue</th>
                            <td>{{ computationResult.entete.is_carriere_longue ? 'OUI' : 'NON' }}</td>

                        </tr>

                        <tr>
                            <th>Date de départ au plus tôt</th>
                            <td>{{ computationResult.entete.dt_depart_plus_tot }}</td>
                        </tr>

                        <tr class="highlight">
                            <th>Date de départ au tôt plein</th>
                            <td>{{ computationResult.entete.dt_depart_tx_plein }}</td>
                        </tr>

                        <tr v-if="computationResult.entete.nb_trim_tous_regimes">
                            <th>Nombre de trimestres (tous régimes)</th>
                            <td>{{ computationResult.entete.nb_trim_tous_regimes.trim }} trimestres</td>
                        </tr>

                        <tr>
                            <th>Selectionnez une date de simulation</th>
                            <td>
                                <select v-model="selected_retirement_date">
                                    <option v-for="(date, index) in Object.keys(computationResult.resultats_a_date)" :key="index" :value="date">{{ date }}</option>
                                </select>
                            </td>
                        </tr>

                        <tr class="highlight">
                            <th>Pension de retraite totale estimée</th>
                            <td>{{ toEuro(computationResult.resultats_a_date[selected_retirement_date].pension_total) }}</td>
                        </tr>

                        <tr>
                            <th>Dont pension de retraite de base estimée</th>
                            <td>{{ toEuro(computationResult.resultats_a_date[selected_retirement_date].regimes.CNAV.pensions.CNAV.pension) }}</td>
                        </tr>

                        <tr class="highlight">
                            <th>Pension de reversion totale estimée</th>
                            <td>{{ toEuro(computationResult.reversions.map(r => r.mnt_reversion).reduce((sum, current) => sum + current, 0)) }}</td>
                        </tr>

                    </table>
                </div>

                <div class="section-element">
                    <h3>Alertes suite à la lecture du RIS</h3>

                    <table class="calculation-result">
                        <tr v-for="(alert, index) in computationResult.questionnaire" :key="index">
                            <td v-html="alert.periodes"></td>
                            <td v-html="alert.question"></td>
                        </tr>
                    </table>
                </div>
            </div>

            <div class="right-section">
                <div class="section-element" v-if="!computationResult">
                    <h3>Résultats</h3>

                    <img src="../ressources/img/illustration.png"/>

                    <p>Lancez le calcul pour obtenir un résultat</p>
                </div>

                <div class="section-element" v-if="computationResult">
                    <h3>Pension de retraite estimée</h3>
                    <h4>{{ toEuro(computationResult.resultats_a_date[selected_retirement_date].pension_total) }}</h4>
                </div>

                <div class="section-element" v-if="computationResult">
                    <h3>Date de référence</h3>
                    <h4>{{ computationResult.entete.dt_ref_droits_acquis }}</h4>
                </div>
            </div>
        </div> 
    </div>
</template>
 
<script>
import axios from 'axios'
 
export default {
    data() {
        return {
            show_detailed_result: false,

            body: {
                nb_enfants: 0,
                sexe: 'H',
                dt_naissance: "01/01/1980",
                revenu: 45000,
                type_revenu: 'B',
                duree_revenu: 'A',
                ris: "",
                periodes_projection: []
            },
            birthdate: "1980-01-01",
            base64_ris: "",

            validation_loading: false, 
            computation_loading: false,

            selected_retirement_date: "",

            base_64_ris_validation_result: undefined,
            computationResult: undefined,
        }
    },
    watch: {
        base64_ris() {
            this.body.ris = this.base64_ris
            this.validateRis()
        },
        birthdate() {
            this.body.dt_naissance = this.dateToString(this.birthdate)
        },
        computationResult: {
            deep: true, 
            handler() {
                const dates = Object.keys(this.computationResult.resultats_a_date)
                this.selected_retirement_date = dates[0]
            }
        }
    },
    methods: {
        async validateRis() {
            try {
                this.validation_loading = true

                const res = await axios.post(this.$store.getters.get_api_url + 'simulators/ris-validation/', {
                    base64_ris: this.base64_ris,
                }, {
                    headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                })
                
                this.base_64_ris_validation_result = res.data
                this.validation_loading = false

            } catch(err) {
                this.validation_loading = false
                console.log(err)
            }
            
        },
        async computeResult() {
            try {
                this.computation_loading = true
                
                if (this.body.ris == "") {
                    console.log("Veuillez uploader un RIS")

                } else {
                    const res = await axios.post(this.$store.getters.get_api_url + 'simulators/ris-computation/', this.body, {
                        headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                    })
                    
                    this.computationResult = res.data
                }

                this.computation_loading = false

            } catch(err) {
                console.log(err)
                this.computation_loading = false
            }
        },
        handleFileDrop(file) {
            this.base64_ris = file
        }
    }
 }
</script>
 
<style src="./style.css" scoped></style>

<style scoped>

.ris-validation-box {
    position: absolute;
    top: 10px;
    right: 10px;
}

.ris-validation-box span {
    display: flex;
    align-items: center;
}

.valid-indicator {
    color: green;
}
.invalid-indicator {
    color: red;
}
</style>