<template>
    <form class="add-form">
        <div class="form-section">
            <label for="category">Catégorie</label>
            <select id="category" v-model="body.type" :disabled="!isOriginalScenario && !isNew">
                <option v-for="(value, key) in $store.getters.rewardTypes" :key="key" :value="key">{{ value }}</option>
            </select>
        </div>

        <div class="form-section">
            <label for="category">Sens de la créance</label>
            <select id="category" v-model="body.direction" :disabled="!isOriginalScenario && !isNew">
                <option v-for="object in directionsCleaned" :key="object.name" :value="object.name">{{ object.label }}</option>
            </select>
        </div>

        <div class="form-section">
            <label for="category">Motif</label>
            <select id="category" v-model="body.label" :disabled="!isOriginalScenario && !isNew">
                <option v-for="(value, key) in $store.getters.rewardLabels" :key="key" :value="key">{{ value.label }}</option>
            </select>
        </div>

        <div class="form-section" v-if="data_needed.includes('remaining_profit')">
            <label for="value">Profit subsistant</label>
            <currency-input v-model="body.remaining_profit" :disabled="!isOriginalScenario && !isNew"/>
        </div>

        <div class="form-section" v-if="data_needed.includes('expense_made')">
            <label for="value">Dépense faite</label>
            <currency-input v-model="body.expense_made" :disabled="!isOriginalScenario && !isNew"/>
        </div>

        <div class="form-section">
            <label for="value">Valeur</label>
            <currency-input v-model="body.value" :disabled="!data_needed.includes('value') ||  (!isOriginalScenario && !isNew)"/>
        </div>

        <div class="form-section">
            <label for="value">Commentaire</label>
            <textarea style="width: 100%" rows=4 v-model="body.comment"></textarea>
        </div>

        <div class="btn-submit grow-effect" @click="submit">Enregistrer</div>
        <p class="errorMsg">{{ errorMsg }}</p>
    </form>
</template>

<script>

export default {
    props: ['import'],
    data() {
        return {
            isNew: true,
            errorMsg: "",
            body: {
                _id: undefined,
                clientId: undefined,
                scenarioId: undefined,
                type: "reward",
                label: "construction_on_field",
                direction: "",
                value: 0,
                expense_made: 0,
                remaining_profit: 0,
                comment: "",
                isIpCreation: false,
            }
        }
    },
    computed: {
        isOriginalScenario() {
            const routeArray = this.$route.fullPath.split('/')

            if (routeArray[3] == 'profile' || this.$route.query.isOriginal) {
                return true
            } else {
                return false
            }
        },
        directionsCleaned() {
            return this.$store.getters.rewardDirections.filter(rd => rd.type == this.body.type)
        },
        data_needed() {
            const label_params = this.$store.getters.rewardLabels[this.body.label]

            if (label_params.calculation_method == 'max') {
                return ['remaining_profit', 'expense_made']

            } else if (label_params.calculation_method == 'min') {
                return ['remaining_profit', 'expense_made']

            } else if (label_params.calculation_method == 'expense_made') {
                return ['expense_made']
                
            } else {
                return ['value']
            }
        },
    },
    watch: {
        "body.label": {
            handler() {
                this.updateValue()
            },
        },
        "body.expense_made": {
            handler() {
                this.updateValue()
            },
        },
        "body.remaining_profit": {
            handler() {
                this.updateValue()
            },
        },

    },
    methods: {
        updateValue() {
            const label_params = this.$store.getters.rewardLabels[this.body.label]

            if (label_params.calculation_method == 'max') {
                this.body.value = Math.max(this.body.expense_made, this.body.remaining_profit)

            } else if (label_params.calculation_method == 'min') {
                this.body.value = Math.min(this.body.expense_made, this.body.remaining_profit)
                
            } else if (label_params.calculation_method == 'expense_made') {
                this.body.value = this.body.expense_made
            }
        },
        submit() {
            if (this.isOriginalScenario) {
                this.body.isIpCreation = false
            } else if (this.isNew) {
                this.body.isIpCreation = true
            }

            if (this.body.type == undefined || this.body.type == "") {
                this.errorMsg = "Précisez le type de créance"

            } else if (this.body.label == "" || this.body.label == undefined) {
                this.errorMsg = "Précisez le motif de la créance"

            } else if (this.body.direction == "" || this.body.direction == undefined) {
                this.errorMsg = "Précisez le sens de la créance"

            } else if (this.body.value == 0) {
                this.errorMsg = "Précisez le montant de la créance"

            } else {
                this.errorMsg = ""

                if (this.isNew) {
                    this.body.addToStore = true
                    this.$store.dispatch('create_reward', this.body)
                } else {
                    this.$store.dispatch('modify_reward', this.body)
                }  

                this.$emit('closeModale')
            }
        },
    },
    mounted() {

        if (this.import) {
            this.isNew = false
            this.body = JSON.parse(JSON.stringify(this.import))

        } else {
            this.body.clientId = this.$route.params.clientId
            this.body.scenarioId = this.$route.params.scenarioId
        }
    }
}
</script>

<style src="./style.css" scoped></style>