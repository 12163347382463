<template>
    <div class="page-container">
        <loader-spin v-if="show_loader"></loader-spin>

        <h2 class="category-title">Carrière professionnelle</h2>

        <form>
            <div class="form-section">
                <label>Sexe</label>
                <select v-model="civility">
                    <option value="mr">Homme</option>
                    <option value="mrs">Femme</option>
                </select>
            </div>

            <div class="form-section">
                <label>Date de naissance</label>
                <input type="date" v-model="birthdate"/>
            </div>

            <div class="form-section">
                <label>Revenu brut annuel actuel</label>
                <currency-input v-model="yearly_gross_income"></currency-input>
            </div>

            <div class="form-section">
                <label>Hypothèse de revalorisation annuelle du revenu</label>
                <percent-input v-model="prct_evo_rem_future"></percent-input>
            </div>

            <div class="form-section">
                <label>Dépôt du RIS au format PDF</label>
                <file-drop @file-dropped="handleFileDrop" v-if="yearly_gross_income > 0 && prct_evo_rem_future"></file-drop>
                <h4 v-else>Veuillez saisir un revenu et une hypothèse de revalorisation</h4>
            </div>
        </form>
        
        <table class="career-table" v-if="ris_result">
            <tr>
                <th>Employeur</th>
                <th>Statut</th>
                <th>Catégorie</th>
                <th>Début</th>
                <th>Fin</th>
            </tr>
            <tr v-for="(job, index) in ris_result.carriere" :key="index">
                <td>{{ job.employeur }}</td>
                <td>{{ job.statut }}</td>
                <td>{{ job.categorie }}</td>
                <td>{{ job.dt_debut }}</td>
                <td>{{ job.dt_fin }}</td>
            </tr>
        </table>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            show_loader: false,
            showRisModale: false,
            base64_ris: undefined,
            base_64_ris_validation_result: undefined,

            birthdate: "",
            civility: 'mr',
            yearly_gross_income: 0,
            prct_evo_rem_future: 1,
            ris_result: undefined,
        }
    },
    methods: {
        toggleRisModale() {
            this.showRisModale = !this.showRisModale
        },
        handleFileDrop(file) {
            this.base64_ris = file
        },
        async upload_ris() {
            try {
                this.show_loader = true

                const res = await axios.post(this.$store.getters.get_api_url + 'simulators/ris-validation/', {
                    base64_ris: this.base64_ris,
                }, {
                    headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                })
                
                this.base_64_ris_validation_result = res.data
                
                if (this.base64_ris == "") {
                    console.log("Veuillez uploader un RIS")

                } else {
                    const trad_civility = {
                        mr: "H",
                        mrs: "F",
                    }

                    const body = {
                        nb_enfants: 0,
                        sexe: trad_civility[this.civility],
                        dt_naissance: this.dateToString(this.birthdate),
                        revenu: this.yearly_gross_income,
                        type_revenu: 'B',
                        duree_revenu: 'A',
                        ris: this.base64_ris,
                        periodes_projection: [],
                    }

                    const res = await axios.post(this.$store.getters.get_api_url + 'simulators/ris-computation/', body, {
                        headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                    })
                    
                    this.ris_result = res.data
                }

                this.show_loader = false
                this.save_career_datas()

            } catch(err) {
                console.log(err)
                this.computation_loading = false
            }
        },
        async save_career_datas() {
            await axios.post(this.$store.getters.get_api_url + 'clients/career/', {
                clientId: this.$route.params.clientId,
                scenarioId: this.$route.params.scenarioId,
                userId: this.userId,
                yearly_gross_income: this.yearly_gross_income,
                prct_evo_rem_future: this.prct_evo_rem_future,
                idmagnaresult: this.ris_result,
            }, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })
        },
        async load_career() {
            const career_result = await axios.get(this.$store.getters.get_api_url + 'clients/career/' + this.userId, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            if (career_result.data) {
                this.ris_result = career_result.data.idmagnaresult
                this.yearly_gross_income = career_result.data.yearly_gross_income
                this.prct_evo_rem_future = career_result.data.prct_evo_rem_future
            } else {
                this.yearly_gross_income = this.$store.getters.getActivityIncome(this.userId);
            }
        }
    },
    computed: {
        userId() {
            const routeArray = this.$route.fullPath.split('/')
            const current_page = routeArray[routeArray.length - 1]

            if (current_page == 'career-user') {
                return this.$store.getters.userId
            } else {
                return this.$store.getters.spouseId
            }
        }
    },
    watch: {
        base64_ris() {
            this.upload_ris()
        },
        userId() {
            if (this.userId) {
                this.civility = this.$store.getters.getCivility(this.userId);
                this.birthdate = this.$store.getters.getBirthdate(this.userId);

                this.ris_result = undefined
                this.yearly_gross_income = 0
                this.prct_evo_rem_future = 1

                this.load_career()
            }
        },
    },
    async mounted() {
    }
}
</script>

<style scoped>
.page-container {
    position: relative;
}
.btn-ris {
    cursor: pointer;
    position: absolute;
    top: 0px;
    right: 20px;
    background-color: rgb(255, 136, 0);
    padding: 15px;
    border-radius: 10px;
    color:white;
    font-weight: bold;
}
.btn-ris:hover {
    background-color: rgba(255, 136, 0, 0.695);
}

.ris-upload-frame {
    padding: 20px;
}

.ris-validation-box {
    position: absolute;
    top: 10px;
    right: 10px;
}

.ris-validation-box span {
    display: flex;
    align-items: center;
}

.valid-indicator {
    color: green;
}
.invalid-indicator {
    color: red;
}

.form-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
}
.form-section label,
.form-section input, 
.form-section select {
    font-family: "Avenir", sans-serif;
    font-size: 18px;
}
.form-section input, 
.form-section select {
    text-align: right;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid lightgrey;
}
.category-title {
    color: rgb(255, 136, 0);
}
.start-date {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.career-table {
    width: 100%;
    border-collapse: collapse;
}

.career-table th {
    color: white;
    background-color: rgb(2, 2, 100);
    border: 1px solid rgb(243, 245, 247);
}

.career-table td {
    background-color: white;
    border: 1px solid rgb(243, 245, 247);
    text-align: center;
}

</style>