<template>
    <form class="add-form">

        <div class="form-section">
            <label for="value">Nom de section</label>
            <input type="text" v-model="body.title">
        </div>

        <div class="form-section">
            <label for="value">Introduction</label>
            <text-editor
                id="add-intro" 
                v-model="body.introduction"
                height="500"
            />
        </div>

        <div class="form-section">
            <label for="value">Conclusion</label>
            <text-editor
                id="add-conclu" 
                v-model="body.conclusion"
                height="500"
            />
        </div>
        
        <div class="btn-submit" @click="submit">Enregistrer</div>
        <p class="errorMsg">{{ errorMsg }}</p>
    </form>
</template>

<script>
import axios from 'axios'

export default {
    props: ['import', 'type'],
    data() {
        return {
            isNew: true,
            errorMsg: "",
            body: {
                _id: undefined,
                type: undefined,
                title: "",
                introduction: "",
                conclusion: "",
            },
        }
    },
    methods: {
        async submit() {
            if (this.body.title == undefined) {
                this.errorMsg = "Titre manquant"
            } else {
                if (this.isNew) {
                    await axios.post(this.$store.getters.get_api_url +'referential/sections/', this.body, {
                        headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                    })

                } else {
                    await axios.put(this.$store.getters.get_api_url +'referential/sections/' + this.import._id, this.body, {
                        headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                    })
                }

                this.$emit('closeModale')
            }
        },
    },
    mounted() {
        this.body.type = this.type

        if (this.import) {
            this.isNew = false
            this.body = JSON.parse(JSON.stringify(this.import))
        }
    }
}
</script>

<style src="../Style.css" scoped></style>