<template>
    <div class="modale-wrapper">

        <div class="form-data">
            <div class="form-section">
                <label>Nom du scenario</label>
                <input type="text" v-model="body.label">
            </div>

            <div class="incomes-section">
                <p>Revenus à remplacer</p>
                <div class="incomes-frame">
                    <div class="incomes" v-for="(income, index) in body.incomesList" :key="index">
                        <label>{{ income.label }}</label>
                        <input type="checkbox" :disabled="income.disabled" v-model="income.is_checked">
                    </div>
                </div>
            </div>

            <div class="form-section">
                <label>Capital social + moyenne des CCA de la société</label>
                <input type="number" v-model="body.share_capital">
            </div>

            <div class="form-section">
                <label>Résultat taxable de la société avant rémunération</label>
                <input type="number" v-model="body.societyTaxesResult">
            </div>

            <div class="form-section">
                <label>Budget de rémunération</label>
                <input type="number" v-model="body.budget">
            </div>

            <div class="form-section">
                <label>Montant des dividendes</label>
                <input type="number" v-model="body.dividends">
            </div>

            <div class="form-section">
                <label>Activité</label>
                <select v-model="body.job_status">
                    <option v-for="(value, key) in $store.getters.jobStatus" :key="key" :value="key">{{ value }}</option>
                </select>
            </div>

            <div class="form-section" v-if="body.job_status == 'doctor'">
                <label>Dirigeant de SEL</label>
                <select v-model="body.is_sel_director">
                    <option :value="true">OUI</option>
                    <option :value="false">NON</option>
                </select>
            </div>
        </div>

        <div class="compute-btn-container">
            <div class="save-btn btn" @click="saveSimulation">Enregistrer</div>
            <div class="delete-btn btn" @click="deleteSimulation" v-if="!isNew">Supprimer</div>
        </div>

        <loader-spin v-if="isLoading"></loader-spin>

        <div v-else class="comparison-table">
            <table>
                <tr>
                    <td>Régime social</td>
                    <td>{{ simulation.social_status }}</td>
                </tr>

                <tr class="important-line">
                    <td>Budget rémunération</td>
                    <td>{{ toEuro(simulation.budget) }}</td>
                </tr>

                <tr>
                    <td>Dividendes</td>
                    <td>{{ toEuro(simulation.dividends) }}</td>
                </tr>

                <tr>
                    <td>Impôt sur les sociétés</td>
                    <td>{{ toEuro(simulation.society_tax) }}</td>
                </tr>

                <tr>
                    <td>Rémunération brute</td>
                    <td>{{ toEuro(simulation.gross_remuneration) }}</td>
                </tr>

                <tr>
                    <td>Cotisations sociales</td>
                    <td>{{ toEuro(simulation.contributionsResult?.contributions) }}</td>
                </tr>

                <tr class="important-line">
                    <td>Revenu net avant impôt</td>
                    <td>{{ toEuro(simulation.net_income_before_taxes) }}</td>
                </tr>

                <tr>
                    <td>PFU</td>
                    <td>{{ toEuro(simulation.pfu) }}</td>
                </tr>

                <tr>
                    <td>Impôt sur le revenu (barème)</td>
                    <td>{{ toEuro(simulation.taxes) }}</td>
                </tr>

                <tr>
                    <td>Prélèvements sociaux</td>
                    <td>{{ toEuro(simulation.social_tax) }}</td>
                </tr>

                <tr class="important-line">
                    <td>Rémunération disponible</td>
                    <td>{{ toEuro(simulation.disposable_income) }}</td>
                </tr>

                <tr>
                    <td colspan="2" style="text-align: center; background-color: orange; font-weight: bold;">Protection sociale</td>
                </tr>

                <tr>
                    <td>Droits à la retraite de base / an</td>
                    <td>{{ simulation.social_protection?.retirement_pension }}</td>
                </tr>

                <tr>
                    <td>Droits à la retraite complémentaires estimés par année de cotisation</td>
                    <td>{{ toEuro(simulation.social_protection?.complementary_pension_yearly.yearly_rights) }}</td>
                </tr>

                <tr>
                    <td>Protection maladie</td>
                    <td>
                        <ul>
                            <li v-for="(el, index) in simulation.social_protection?.seakness_protection" :key="index">{{ el }}</li>
                        </ul>
                    </td>
                </tr>

                <tr>
                    <td>Indémnités journalières</td>
                    <td></td>
                </tr>

                <tr>
                    <td>Invalidité</td>
                    <td>
                        <ul>
                            <li v-for="(el, key) in simulation.social_protection?.disability" :key="key">{{ key }} : {{ el }}</li>
                        </ul>
                    </td>
                </tr>

                <tr>
                    <td>Droits au chômage</td>
                    <td>Aucun droit au chômage</td>
                </tr>
            </table>

            {{ errorMsg }}
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    props: ['simulationObj', 'selectedScenario'],
    data() {
        return {
            isNew: true,
            showModale: false,
            isLoading: false,
            body: {
                clientId: this.$route.params.clientId,
                scenarioId: this.selectedScenario,
                label: "Simulation 1",
                job_status: 'craftman',
                budget: undefined,
                dividends: 0,
                auto_repartition: false,
                share_capital: 0,
                is_sel_director: false,
                societyTaxesResult: 0,
                incomesList: [],
            },
            errorMsg: "",
            simulation: {},
        }
    },
    watch: {
        body: {
            deep: true, 
            handler() {
                this.compute()
            }
        },
    },
    methods: {
        async loadIncomes() {
            this.body.incomesList = []
            const resRecommendation = await axios.get(this.$store.getters.get_api_url + 'clients/find-recommendation/' + this.$route.params.recommendationId, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            const res = await axios.get(this.$store.getters.get_api_url + 'clients/income/' + resRecommendation.data.selectedScenario, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            res.data.forEach(income => {
                if (!this.body.incomesList.map(i => i._id).includes(income._id)) {
                    this.body.incomesList.push({
                        _id: income._id,
                        label: this.$store.getters.incomeTypes[income.type] + " - " + this.$store.getters.findPersonName(income.person_id) + " - " + this.toEuro(income.net_value),
                        is_checked: false,
                        disabled: false,
                    })
                }
            });
        },
        async compute() {
            this.isLoading = true

            let taxes_datas = this.$store.getters.taxesDatas
            taxes_datas.replaced_incomes = this.body.incomesList.filter(i => i.is_checked).map(i => i._id)
            
            try {
                const res = await axios.post(this.$store.getters.get_api_url + 'simulators/management-remuneration/', {
                    datas: this.body,
                    taxes_datas: taxes_datas
                }, {
                    headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                })

                this.simulation = res.data

            } catch(err) {
                console.log(err)
            }
            
            this.isLoading = false
            
        },
        async saveSimulation() {
            if (this.body.budget == 0 || !this.body.budget) {
                this.$store.commit('changeErrorMsg', "Le budget rémunération doit être supérieur à 0")

            } else {
                if (this.isNew) {
                    const res = await axios.post(this.$store.getters.get_api_url + 'clients/manager-remuneration/', this.body, {
                        headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                    })
                    this.body._id = res.data
                    if (res.status == 200) {
                        this.$store.commit('changeValidationMsg', "Enregistrement réalisé avec succès")
                    } else {
                        this.$store.commit('changeErrorMsg', "Erreur lors de l'enregistrement")
                    }
                    this.isNew = false
                    this.$emit('modification')
                    this.$emit('toggle')

                } else {
                    const res = await axios.put(this.$store.getters.get_api_url + 'clients/manager-remuneration/' + this.body._id, this.body, {
                        headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                    })
                    if (res.status == 200) {
                        this.$store.commit('changeValidationMsg', "Enregistrement réalisé avec succès")
                    } else {
                        this.$store.commit('changeErrorMsg', "Erreur lors de l'enregistrement")
                    }
                    this.$emit('toggle')
                }
            }
        },
        async deleteSimulation() {
            await axios.delete(this.$store.getters.get_api_url + 'clients/manager-remuneration/' + this.body._id, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })
            
            this.$emit('modification')
            this.$emit('toggle')
        }
    },
    mounted() {
        this.loadIncomes()

        if (this.simulationObj != undefined) {
            this.isNew = false
            this.body = this.simulationObj
        } else {
            this.body = {
                clientId: this.$route.params.clientId,
                scenarioId: this.selectedScenario,
                label: "Simulation 1",
                job_status: 'craftman',
                budget: 200000,
                dividends: 0,
                auto_repartition: false,
                share_capital: 0,
                societyTaxesResult: 0,
                is_sel_director: false,
                incomesList: [],
            }
        }
    }
}
</script>

<style scoped>
.modale-wrapper {
    width: 70%;
    margin: 20px auto;
}

/* Formulaire */
.incomes-section {
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
}
.incomes-frame {
    display: flex;
    flex-direction: column;
}
.incomes {
    display: flex;
    flex-basis: 70%;
    justify-content: right;
    font-weight: lighter;
}
.incomes input[type] {
    height: 20px;
    width: 20px;
    flex-basis: auto;
}

.detail-btn {
    cursor: pointer;
    font-size: 18px;
    background-color: rgb(0, 0, 96);
    color: white;
    display: inline-block;
    padding: 10px;
    margin-left: 15px;
    border-radius: 5px;
}

/* Bouton */
.compute-btn-container {
    display: flex;
    justify-content: center;
    padding: 10px;
}

.btn {
    cursor: pointer;
    display: inline-block;
    padding: 10px 20px;
    align-self: center;
    border-radius: 10px;
    font-size: 18px;
    margin: 10px;
}
.compute-btn {
    background-color: orange;
}
.compute-btn:hover {
    background-color: rgb(251, 183, 55);
}

.delete-btn {
    background-color: rgb(179, 1, 1);
    color: white;
}
.delete-btn:hover {
    background-color: red;
}

.save-btn {
    background-color: green;
    color: white;
}
.save-btn:hover {
    background-color: rgb(4, 186, 4);
}

/* Tableau de comparaison */
.comparison-table table {
    border-collapse: collapse;
    width: 100%;
    font-size: 13px;
}

.comparison-table th, .comparison-table td {
    padding: 10px;
    border: 1px solid grey;
    position: relative;
}

.comparison-table table th {
    background-color: rgb(0, 0, 96);
    color: white;
}

.important-line {
    background-color: rgb(172, 192, 247);
    font-weight: bold;
}

.comparison-table table td+td {
    text-align: center;
}

.in-row-input {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 0;
    border: none;
}


</style>