<template>
    <div class="audit-page">
        <h1>Audit patrimonial (1/4)</h1>

        <div class="btn-frame">
            <div class="add-btn" @click="toggleModale">Ajouter un rappel</div>
            <div class="default-btn" @click="getAutoReminders">Auto</div>
        </div>

        <div class="categories">
            <div class="category">
                <h2 class="category-title">Bilan patrimonial</h2>

                <draggable class="dragArea list-group w-full" v-model="wealth_audit_list" group="audit">
                    <div class="element" v-for="el in wealth_audit_list" :key="el._id">
                        <div>{{ reminders.find(r => r._id == el)?.title }}</div>
                        <div class="delete-btn" @click="deleteReminder(el)">X</div>
                    </div>
                </draggable>
            </div>

            <div class="category">
                <h2 class="category-title">Bilan fiscal</h2>

                <draggable class="dragArea list-group w-full" v-model="tax_audit_list" group="audit">
                    <div class="element" v-for="el in tax_audit_list" :key="el._id">
                        <div>{{ reminders.find(r => r._id == el)?.title }}</div>
                        <div class="delete-btn" @click="deleteReminder(el)">X</div>
                    </div>
                </draggable>
            </div>

            <div class="category">
                <h2 class="category-title">Bilan transmission</h2>

                <draggable class="dragArea list-group w-full" v-model="legacy_audit_list" group="audit">
                    <div class="element" v-for="el in legacy_audit_list" :key="el._id">
                        <div>{{ reminders.find(r => r._id == el)?.title }}</div>
                        <div class="delete-btn" @click="deleteReminder(el)">X</div>
                    </div>
                </draggable>
            </div>
        </div>

        <modale :show="showModale" @toggle="toggleModale">
            <div class="referential">

                <input type="text" v-model="researchString">

                <div class="element" v-for="el in unselected_reminders" :key="el._id">
                    <div>n° {{ el.number }} - {{ el.title }}</div>
                    <div class="delete-btn" @click="addReminder(el._id)">+</div>
                </div>
            </div>
        </modale>
    </div>
</template>

<script>
import axios from 'axios'
import { VueDraggableNext } from 'vue-draggable-next'

export default {
    components: {
        draggable: VueDraggableNext,
    },
    data() {
        return {
            trad_sections: {
                '657b3072eafd20662ef8217e': 'wealth_audit_list',
                '657df0129fbc7d1732078595': 'legacy_audit_list',
                '66547d5b31401c1e764754f2': 'tax_audit_list',
            },
            recommendation: {},
            reminders: [],
            showModale: false,
            wealth_audit_list: [],
            legacy_audit_list: [],
            tax_audit_list: [],
            researchString: "",
            searched_reminders: [],
        }
    },
    methods: {
        async loadReminders() {
            const result = await axios.get(this.$store.getters.get_api_url +'referential/reminders/', {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            this.reminders = result.data
            this.searched_reminders = result.data
        },
        toggleModale() {
            this.showModale = !this.showModale
        },
        addReminder(id) {
            const reminder = this.reminders.find(r => r._id == id)

            if (this.trad_sections[reminder.sectionId] == 'legacy_audit_list') {
                this.legacy_audit_list.push(id)

            } else if (this.trad_sections[reminder.sectionId] == 'tax_audit_list') {
                this.tax_audit_list.push(id)
                
            } else {
                this.wealth_audit_list.push(id)
            }

            this.toggleModale()
        },
        deleteReminder(id) {
            this.wealth_audit_list = this.wealth_audit_list.filter(r => r != id)
            this.legacy_audit_list = this.legacy_audit_list.filter(r => r != id)
            this.tax_audit_list = this.tax_audit_list.filter(r => r != id)
        },
        async getAutoReminders() {
            const wealthRes = await axios.get(this.$store.getters.get_api_url +'referential/wealth-reminders/' + this.$route.params.clientId, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            this.wealth_audit_list = wealthRes.data

            const legacyRes = await axios.get(this.$store.getters.get_api_url +'referential/legacy-reminders/' + this.$route.params.clientId, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            this.legacy_audit_list = legacyRes.data
        },
        async searchReminders() {
            if (this.researchString == "") {
                this.loadReminders()
            } else {
                const result = await axios.post(this.$store.getters.get_api_url +'referential/reminders-research/', {
                    research: this.researchString
                } , {
                    headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                })

                this.searched_reminders = result.data
            }
        }
    },
    watch: {
        wealth_audit_list: {
            deep: true, 
            handler() {
                axios.put(this.$store.getters.get_api_url +'clients/recommendation/', {
                    ...this.recommendation,
                    wealth_audit_list: this.wealth_audit_list,
                    legacy_audit_list: this.legacy_audit_list,
                    tax_audit_list : this.tax_audit_list,
                }, {
                    headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                })
            }
        },
        tax_audit_list: {
            deep: true, 
            handler() {
                axios.put(this.$store.getters.get_api_url +'clients/recommendation/', {
                    ...this.recommendation,
                    wealth_audit_list: this.wealth_audit_list,
                    legacy_audit_list: this.legacy_audit_list,
                    tax_audit_list : this.tax_audit_list,
                }, {
                    headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                })
            }
        },
        legacy_audit_list: {
            deep: true, 
            handler() {
                axios.put(this.$store.getters.get_api_url +'clients/recommendation/', {
                    ...this.recommendation,
                    wealth_audit_list: this.wealth_audit_list,
                    legacy_audit_list: this.legacy_audit_list,
                    tax_audit_list : this.tax_audit_list,
                }, {
                    headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                })
            }
        },
        researchString() {
            this.searchReminders()
        }
    }, 
    computed: {
        unselected_reminders() {
            const selected_reminders = [...this.wealth_audit_list, ...this.legacy_audit_list, ...this.tax_audit_list]

            return this.searched_reminders.filter(r => !selected_reminders.includes(r._id))
        }
    },
    async mounted() {
        const res = await axios.get(this.$store.getters.get_api_url +'clients/find-recommendation/' + this.$route.params.recommendationId, {
            headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
        })

        this.recommendation = res.data
        this.wealth_audit_list = this.recommendation.wealth_audit_list
        this.legacy_audit_list = this.recommendation.legacy_audit_list
        this.tax_audit_list = this.recommendation.tax_audit_list

        if (this.wealth_audit_list.length + this.legacy_audit_list.length + this.tax_audit_list.length == 0) {
            this.getAutoReminders()
        }

        this.loadReminders()
    }
}
</script>

<style scoped>
.audit-page {
    position: relative;
    margin: 20px;
}
</style>

<style src="./style.css" scoped/>