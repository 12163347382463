<template>
    <input
        type="text"
        :value="formattedValue"
        @input="onInput"
        @blur="onBlur"
        @focus="onFocus"
    />
</template>
  
<script>
    export default {
        name: "PercentageInput",
        props: {
        modelValue: {
            type: Number,
            default: 0, // Valeur initiale
        },
        },
        computed: {
        // Format la valeur avec le signe %
        formattedValue() {
            return this.modelValue != null ? `${this.modelValue}%` : "";
        },
        },
        methods: {
        // Met à jour la valeur lorsqu'un utilisateur modifie l'input
        onInput(event) {
            const rawValue = event.target.value.replace("%", "").trim(); // Supprime le %
            const numericValue = parseFloat(rawValue);

            if (!isNaN(numericValue)) {
                this.$emit("update:modelValue", numericValue); // Met à jour la valeur via v-model
            } else if (rawValue === "") {
                this.$emit("update:modelValue", null); // Remet à null si vide
            }
        },
        // Enlève le signe % au focus (pour simplifier la modification)
        onFocus(event) {
            event.target.value = this.modelValue != null ? this.modelValue : "";
        },
        // Ajoute le signe % après modification
        onBlur(event) {
            event.target.value = this.formattedValue;
        },
        },
    };
</script>
  
<style scoped>
input {
    text-align: right;
}
</style>
  