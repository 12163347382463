<template>
    <div class="taxes">
        <select v-model="year">
            <option v-for="(value, key) in years" :key="key" :value="key">{{ value }}</option>
        </select>

        <table class="taxes-choices">

            <tr>
                <td>Imposition au barème progressif des revenus de capitaux mobiliers</td>
                <td><input type="checkbox" v-model="choices.bpi_pvmo"></td>
            </tr>
        </table>

        <table class="taxes-infos">
            <tr>
                <th>Situation familiale</th>
                <th>Nombre de parts fiscales</th>
                <th>Taux marginal</th>
            </tr>

            <tr>
                <td>{{ situations[$store.state.marital_contract.situation] }}</td>
                <td>{{ taxesResults.FamilyQuotient?.nb_part_couple + taxesResults.FamilyQuotient?.nb_part_dependant }}</td>
                <td>{{ taxesResults.tmi }} %</td>
            </tr>
        </table>

        <table class="computation">
            <tr>
                <th class="sect-title">Impôt 2022</th>
                <th class="sect-title">Déclarant 1</th>
                <th class="sect-title">Déclarant 2</th>
                <th class="sect-title">Total</th>
            </tr>

            <tr v-if="hasSalaries">
                <td>Traitements et salaires</td>
                <td>{{ toEuro(taxesResults.rbgComputation?.total_incomes_list[$store.getters.userId]?.total_salaries, 0) }}</td>
                <td>{{ toEuro(taxesResults.rbgComputation?.total_incomes_list[$store.getters.spouseId]?.total_salaries, 0) }}</td>
                <td></td>
            </tr>

            <tr v-if="hasSalaries">
                <td>Déduction 10% ou frais réels</td>
                <td>- {{ toEuro(taxesResults.rbgComputation?.total_charges_list[$store.getters.userId]?.salaries_charges, 0) }}</td>
                <td>- {{ toEuro(taxesResults.rbgComputation?.total_charges_list[$store.getters.spouseId]?.salaries_charges, 0) }}</td>
                <td></td>
            </tr>

            <tr v-if="hasFinancialIncomes">
                <td>Revenus de capitaux mobiliers</td>
                <td>{{ toEuro(taxesResults.rbgComputation?.total_incomes_list[$store.getters.userId]?.dividend + taxesResults.rbgComputation?.total_incomes_list[$store.getters.userId]?.financial_income , 0) }}</td>
                <td>{{ toEuro(taxesResults.rbgComputation?.total_incomes_list[$store.getters.spouseId]?.dividend + taxesResults.rbgComputation?.total_incomes_list[$store.getters.userId]?.financial_income , 0) }}</td>
                <td></td>
            </tr>

            <tr v-if="hasFinancialIncomes">
                <td>Abattement de 40% sur les dividendes</td>
                <td>{{ toEuro(taxesResults.rbgComputation?.total_charges_list[$store.getters.userId]?.dividend, 0) }}</td>
                <td>{{ toEuro(taxesResults.rbgComputation?.total_charges_list[$store.getters.spouseId]?.dividend, 0) }}</td>
                <td></td>
            </tr>
            
            <tr v-if="hasPropertyIncomes">
                <td>Revenus fonciers</td>
                <td>{{ toEuro(taxesResults.rbgComputation?.total_incomes_list[$store.getters.userId]?.total_property_incomes, 0) }}</td>
                <td>{{ toEuro(taxesResults.rbgComputation?.total_incomes_list[$store.getters.spouseId]?.total_property_incomes, 0) }}</td>
                <td></td>
            </tr>

            <tr v-if="hasPropertyIncomes">
                <td>Charges foncières</td>
                <td>- {{ toEuro(Math.min(taxesResults.rbgComputation?.total_incomes_list[$store.getters.userId]?.total_property_incomes,taxesResults.rbgComputation?.total_charges_list[$store.getters.userId]?.property_incomes_charges), 0) }}</td>
                <td>- {{ toEuro(Math.min(taxesResults.rbgComputation?.total_incomes_list[$store.getters.spouseId]?.total_property_incomes, taxesResults.rbgComputation?.total_charges_list[$store.getters.userId]?.property_incomes_charges), 0) }}</td>
                <td></td>
            </tr>
            
            <tr v-if="hasBic1">
                <td>BIC - Activité de service</td>
                <td>{{ toEuro(taxesResults.rbgComputation?.total_incomes_list[$store.getters.userId]?.total_bic1, 0) }}</td>
                <td>{{ toEuro(taxesResults.rbgComputation?.total_incomes_list[$store.getters.spouseId]?.total_bic1, 0) }}</td>
                <td></td>
            </tr>

            <tr v-if="hasBic2">
                <td>BIC - Vente de biens</td>
                <td>{{ toEuro(taxesResults.rbgComputation?.total_incomes_list[$store.getters.userId]?.total_bic2, 0) }}</td>
                <td>{{ toEuro(taxesResults.rbgComputation?.total_incomes_list[$store.getters.spouseId]?.total_bic2, 0) }}</td>
                <td></td>
            </tr>

            <tr v-if="hasLMNP">
                <td>BIC - Loueur meublé non professionnel</td>
                <td>{{ toEuro(taxesResults.rbgComputation?.total_incomes_list[$store.getters.userId]?.total_lmnp, 0) }}</td>
                <td>{{ toEuro(taxesResults.rbgComputation?.total_incomes_list[$store.getters.spouseId]?.total_lmnp, 0) }}</td>
                <td></td>
            </tr>

            <tr v-if="hasLMP">
                <td>BIC - Loueur meublé professionnel</td>
                <td>{{ toEuro(taxesResults.rbgComputation?.total_incomes_list[$store.getters.userId]?.total_lmp, 0) }}</td>
                <td>{{ toEuro(taxesResults.rbgComputation?.total_incomes_list[$store.getters.spouseId]?.total_lmp, 0) }}</td>
                <td></td>
            </tr>

            <tr v-if="hasBic1 || hasBic2 || hasLMNP || hasLMP">
                <td>Charges BIC</td>
                <td></td>
                <td></td>
                <td>- {{ toEuro(total_bic_charges, 0) }}</td>
            </tr>

            <tr v-if="hasBnc">
                <td>Bénéfices non commerciaux</td>
                <td>{{ toEuro(taxesResults.rbgComputation?.total_incomes_list[$store.getters.userId]?.total_bnc, 0) }}</td>
                <td>{{ toEuro(taxesResults.rbgComputation?.total_incomes_list[$store.getters.spouseId]?.total_bnc, 0) }}</td>
                <td></td>
            </tr>

            <tr v-if="hasBnc">
                <td>Charges BNC</td>
                <td>- {{ toEuro(taxesResults.rbgComputation?.total_charges_list[$store.getters.userId]?.bnc_charges, 0) }}</td>
                <td>- {{ toEuro(taxesResults.rbgComputation?.total_charges_list[$store.getters.spouseId]?.bnc_charges, 0) }}</td>
                <td></td>
            </tr>

            <tr v-if="hasRetirement">
                <td>Pensions de retraite</td>
                <td>{{ toEuro(taxesResults.rbgComputation?.total_incomes_list[$store.getters.userId]?.total_retirement, 0) }}</td>
                <td>{{ toEuro(taxesResults.rbgComputation?.total_incomes_list[$store.getters.spouseId]?.total_retirement, 0) }}</td>
                <td></td>
            </tr>

            <tr v-if="hasRetirement">
                <td>Déduction 10%</td>
                <td>- {{ toEuro(taxesResults.rbgComputation?.total_charges_list[$store.getters.userId]?.retirement_charges, 0) }}</td>
                <td>- {{ toEuro(taxesResults.rbgComputation?.total_charges_list[$store.getters.spouseId]?.retirement_charges, 0) }}</td>
                <td></td>
            </tr>

            <tr v-if="taxesResults.rbgComputation?.property_deficit_on_rbg > 0">
                <td>Déficit foncier déductible du revenu global</td>
                <td></td>
                <td></td>
                <td>{{ toEuro(taxesResults.rbgComputation?.property_deficit_on_rbg, 0) }}</td>
            </tr>

            <tr>
                <th class="sect-title">REVENU BRUT GLOBAL</th>
                <th></th>
                <th></th>
                <th>{{ toEuro(taxesResults.rbgComputation?.rbg, 0) }}</th>
            </tr>

            <tr v-if="taxesResults.rngComputation?.child_with_family_total_deduction > 0">
                <td>Déduction pour enfants rattachés et chargés de famille</td>
                <td></td>
                <td></td>
                <td>{{ toEuro(taxesResults.rngComputation?.child_with_family_total_deduction, 0) }}</td>
            </tr>

            <tr v-if="taxesResults.rngComputation?.total_per_deduction > 0">
                <td>Versements sur un PER</td>
                <td>{{ toEuro(taxesResults.rngComputation?.total_per_deduction, 0) }}</td>
                <td></td>
                <td></td>
            </tr>

            <tr v-if="taxesResults.rngComputation?.total_fixed_alimony > 0">
                <td>Pension alimentaire forfaitaire - hébergement d'un enfant non rattaché</td>
                <td></td>
                <td></td>
                <td>{{ toEuro(taxesResults.rngComputation?.total_fixed_alimony, 0) }}</td>
            </tr>

            <tr v-if="taxesResults.rngComputation?.total_other_alimony > 0">
                <td>Pensions alimentaires versées</td>
                <td></td>
                <td></td>
                <td>{{ toEuro(taxesResults.rngComputation?.total_other_alimony, 0) }}</td>
            </tr>

            <tr v-if="taxesResults.rngComputation?.deductible_csg > 0">
                <td>CSG déductible</td>
                <td></td>
                <td></td>
                <td>{{ toEuro(taxesResults.rngComputation?.deductible_csg, 0) }}</td>
            </tr>

            <tr>
                <th>REVENU NET GLOBAL</th>
                <th></th>
                <th></th>
                <th>{{ toEuro(taxesResults.rngComputation?.rng, 0) }}</th>
            </tr>

            <tr>
                <td>Impôt sur le revenu brut</td>
                <td></td>
                <td></td>
                <td>{{ toEuro(taxesResults.grossTaxes, 0) }}</td>
            </tr>

            <tr v-if="taxesResults.discount">
                <td>Décôte</td>
                <td></td>
                <td></td>
                <td>{{ toEuro(taxesResults.discount, 0) }}</td>
            </tr>

            <tr v-if="taxesResults.discount">
                <td>Impôt sur le revenu brut après décôte</td>
                <td></td>
                <td></td>
                <td>{{ toEuro(taxesResults.grossTaxes - taxesResults.discount, 0) }}</td>
            </tr>

            <tr v-if="taxesResults.netTaxes?.fcpi_reduction">
                <td>Réduction d'impôt (FCPI)</td>
                <td></td>
                <td></td>
                <td>- {{ toEuro(taxesResults.netTaxes?.fcpi_reduction, 0) }}</td>
            </tr>

            <tr v-if="taxesResults.netTaxes?.sofica_reduction">
                <td>Réduction d'impôt (SOFICA)</td>
                <td></td>
                <td></td>
                <td>- {{ toEuro(taxesResults.netTaxes?.sofica_reduction, 0) }}</td>
            </tr>

            <tr v-if="taxesResults.netTaxes?.fip_reduction">
                <td>Réduction d'impôt (FIP)</td>
                <td></td>
                <td></td>
                <td>- {{ toEuro(taxesResults.netTaxes?.fip_reduction, 0) }}</td>
            </tr>

            <tr v-if="taxesResults.netTaxes?.malraux_reduction">
                <td>Réduction d'impôt (Malraux)</td>
                <td></td>
                <td></td>
                <td>- {{ toEuro(taxesResults.netTaxes?.malraux_reduction, 0) }}</td>
            </tr>

            <tr v-if="taxesResults.netTaxes?.pinel_reduction">
                <td>Réduction d'impôt (Pinel)</td>
                <td></td>
                <td></td>
                <td>- {{ toEuro(taxesResults.netTaxes?.pinel_reduction, 0) }}</td>
            </tr>

            <tr v-if="taxesResults.netTaxes?.people_in_difficulty_reduction">
                <td>Dons aux personnes en difficulté</td>
                <td></td>
                <td></td>
                <td>- {{ toEuro(taxesResults.netTaxes?.people_in_difficulty_reduction, 0) }}</td>
            </tr>

            <tr v-if="taxesResults.netTaxes?.general_interest_organization_reduction">
                <td>Dons à des organismes d'intérêt général</td>
                <td></td>
                <td></td>
                <td>- {{ toEuro(taxesResults.netTaxes?.general_interest_organization_reduction, 0) }}</td>
            </tr>

            <tr v-if="taxesResults.netTaxes?.home_workers_reduction">
                <td>Emploi de salariés à domicile</td>
                <td></td>
                <td></td>
                <td>- {{ toEuro(taxesResults.netTaxes?.home_workers_reduction, 0) }}</td>
            </tr>

            <tr v-if="taxesResults.netTaxes?.children_scolarity_reduction">
                <td>Forfait scolarité</td>
                <td></td>
                <td></td>
                <td>- {{ toEuro(taxesResults.netTaxes?.children_scolarity_reduction, 0) }}</td>
            </tr>
            <!-- Etc.... -->

            <tr>
                <th>IMPÔT NET A PAYER</th>
                <th></th>
                <th></th>
                <th>{{ toEuro(taxesResults.netTaxes?.taxes, 0) }}</th>
            </tr>

            <tr v-if="taxesResults.pfu > 0">
                <th>PRÉLÈVEMENT FORFAITAIRE UNIQUE</th>
                <th></th>
                <th></th>
                <th>{{ toEuro(taxesResults.pfu, 0) }}</th>
            </tr>

            <tr v-if="taxesResults.pfu > 0">
                <th>IMPÔT NET TOTAL</th>
                <th></th>
                <th></th>
                <th>{{ toEuro(taxesResults.pfu + taxesResults.netTaxes?.taxes, 0) }}</th>
            </tr>

            <tr v-if="taxesResults.rbgComputation?.property_deficit_reportable > 0">
                <td>Déficit foncier reportable sur 10 ans</td>
                <td></td>
                <td></td>
                <td>{{ toEuro(taxesResults.rbgComputation?.property_deficit_reportable, 0) }}</td>
            </tr>

            <tr v-if="taxesResults.rbgComputation?.property_deficit_on_rbg_reportable > 0">
                <td>Déficit foncier reportable sur le RBG des 6 prochaines années</td>
                <td></td>
                <td></td>
                <td>{{ toEuro(taxesResults.rbgComputation?.property_deficit_on_rbg_reportable, 0) }}</td>
            </tr>
        </table>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            year: 2023,
            situations: {
                alone: 'Célibataire',
                married: 'Marié',
                pacs: 'Pacsés',
                widowed: 'Veuf/veuve',
                cohabitation: 'Célibataire'
            },
            choices: {
                bpi_pvmo: false,
            },
            years: {
                2020: "Revenus de l'année 2020",
                2021: "Revenus de l'année 2021",
                2022: "Revenus de l'année 2022",
                2023: "Revenus de l'année 2023",
            },
            taxesResults: {}
        }
    },
    watch: {
        choices: {
            handler() {
                this.computeTaxes()
            },
            deep: true,
        },
        year() {
            this.computeTaxes()
        }
    },
    computed: {
        hasSalaries() {
            if (
                this.taxesResults.rbgComputation?.total_incomes_list[this.$store.getters.userId]?.total_salaries ||
                this.taxesResults.rbgComputation?.total_incomes_list[this.$store.getters.spouseId]?.total_salaries
            ) {
                return true
            } else {
                return false
            }
        },
        hasFinancialIncomes() {
            let total = 0
            if (this.taxesResults.rbgComputation) {
                Object.keys(this.taxesResults.rbgComputation.total_incomes_list).forEach(declarant => {
                    total += this.taxesResults.rbgComputation.total_incomes_list[declarant].dividend
                    total += this.taxesResults.rbgComputation.total_incomes_list[declarant].financial_income
                })
            }   
            
            if (total > 0) {
                return true
            } else {
                return false
            }
        },
        hasPropertyIncomes() {
            if (
                this.taxesResults.rbgComputation?.total_incomes_list[this.$store.getters.userId]?.total_property_incomes ||
                this.taxesResults.rbgComputation?.total_incomes_list[this.$store.getters.spouseId]?.total_property_incomes
            ) {
                return true
            } else {
                return false
            }
        },
        hasBic1() {
            if (
                this.taxesResults.rbgComputation?.total_incomes_list[this.$store.getters.userId]?.total_bic1 ||
                this.taxesResults.rbgComputation?.total_incomes_list[this.$store.getters.spouseId]?.total_bic1
            ) {
                return true
            } else {
                return false
            }
        },
        hasBic2() {
            if (
                this.taxesResults.rbgComputation?.total_incomes_list[this.$store.getters.userId]?.total_bic2 ||
                this.taxesResults.rbgComputation?.total_incomes_list[this.$store.getters.spouseId]?.total_bic2
            ) {
                return true
            } else {
                return false
            }
        },
        hasLMNP() {
            if (
                this.taxesResults.rbgComputation?.total_incomes_list[this.$store.getters.userId]?.total_lmnp ||
                this.taxesResults.rbgComputation?.total_incomes_list[this.$store.getters.spouseId]?.total_lmnp
            ) {
                return true
            } else {
                return false
            }
        },
        hasLMP() {
            if (
                this.taxesResults.rbgComputation?.total_incomes_list[this.$store.getters.userId]?.total_lmp ||
                this.taxesResults.rbgComputation?.total_incomes_list[this.$store.getters.spouseId]?.total_lmp
            ) {
                return true
            } else {
                return false
            }
        },
        hasBnc() {
            if (
                this.taxesResults.rbgComputation?.total_incomes_list[this.$store.getters.userId]?.total_bnc ||
                this.taxesResults.rbgComputation?.total_incomes_list[this.$store.getters.spouseId]?.total_bnc
            ) {
                return true
            } else {
                return false
            }
        },
        hasRetirement() {
            if (
                this.taxesResults.rbgComputation?.total_incomes_list[this.$store.getters.userId]?.total_retirement ||
                this.taxesResults.rbgComputation?.total_incomes_list[this.$store.getters.spouseId]?.total_retirement
            ) {
                return true
            } else {
                return false
            }
        },
        total_bic_charges() {
            let result = 0
            result += this.taxesResults.rbgComputation?.total_charges_list[this.$store.getters.userId]?.bic1_charges + this.taxesResults.rbgComputation?.total_charges_list[this.$store.getters.spouseId]?.bic1_charges
            result += this.taxesResults.rbgComputation?.total_charges_list[this.$store.getters.userId]?.bic2_charges + this.taxesResults.rbgComputation?.total_charges_list[this.$store.getters.spouseId]?.bic2_charges
            result += this.taxesResults.rbgComputation?.total_charges_list[this.$store.getters.userId]?.lmnp_charges + this.taxesResults.rbgComputation?.total_charges_list[this.$store.getters.spouseId]?.lmnp_charges
            result += this.taxesResults.rbgComputation?.total_charges_list[this.$store.getters.userId]?.lmp_charges + this.taxesResults.rbgComputation?.total_charges_list[this.$store.getters.spouseId]?.lmp_charges
            
            return result
        }
    },
    methods: {
        async computeTaxes() {
            try {
                let body = this.$store.getters.taxesDatas
                body.choices = this.choices
                body.year = this.year
                
                const taxes = await axios.post(this.$store.getters.get_api_url + 'simulators/income-taxes', body, {
                    headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
                })
                this.taxesResults = taxes.data
            } catch(err) {
                console.log(err)
                this.taxesResults = {}
            }
            
        },
    },
    mounted() {
        this.computeTaxes()
    }
}
</script>

<style scoped>
/* div globale */
.taxes {
    padding: 0 30px 0 30px;
}

/* Choix fiscaux */
table.taxes-choices {
    table-layout: fixed;
    width: 100%;
    border-spacing : 0;
    border-collapse : collapse;
}

.taxes-infos td {
    text-align: center;
    border: solid 1px grey;
}

/* Infos */
table.taxes-infos {
    table-layout: fixed;
    width: 100%;
    border-spacing : 0;
    border-collapse : collapse;
}

table.taxes-infos th {
    background-color: rgb(0, 0, 66);
    text-align: center;
    border: solid 1px grey;
    color: white;
    padding: 5px;
}
table.taxes-infos td {
    text-align: center;
    border: solid 1px grey;
}

/* Résultat */
table.computation {
    table-layout: fixed;
    border-collapse : collapse;
    width: 100%;
    font-size: 12px;
    padding: 20px;
    margin-top: 15px;
    border-spacing: 0;
    text-align: center;
}
.computation td:nth-child(1) {
    text-align: left;
}
.computation th {
    background-color: #F8F4EB;
    border: 1px solid black;
    padding: 5px;
}

.computation td {
    border: 1px solid black;
    padding: 5px;
}

.computation .sect-title {
    padding-top: 10px;
    font-weight: bold;
}


</style>