<template>
    <div>
        <div class="menu">
            <div :class="{'menu-selection': !show_detailed_result}" @click="show_detailed_result=false">Données de simulation</div>
            <div :class="{'menu-selection': show_detailed_result, 'menu-disabled': computationResult == undefined}" @click="computationResult ? show_detailed_result=true : null">Résultat détaillé</div>
        </div>

        <div class="calculator-frame">
            <div class="left-section" v-if="!show_detailed_result">
                <div class="section-element">
                    <h3>Données de calcul</h3>

                    <div class="form-section">
                        <div class="input-frame">
                            <label>Régime social</label>
                            <select v-model="job_status">
                                <option v-for="(value, key) in $store.getters.jobStatus" :key="key" :value="key">{{ value }}</option>
                            </select>
                        </div>

                        <div class="input-frame">
                            <label>Rémunération « Super brut »</label>
                            <currency-input v-model="gross_income"/>
                        </div>
                    </div>
                </div>

                <div class="section-element btn-section">
                    <div class="btn-send-computation btn-grow" @click="computeResult">Calculer</div>
                </div>
            </div>

            <div class="left-section" v-else>
                <div class="section-element">
                    <h3>Résultat détaillé</h3>
                    <h5>Base de calcul des cotisations : {{ toEuro(computationResult.contribution_base) }}</h5>

                    <table v-if="job_status == 'employee'">
                        <tr class="highlight">
                            <th>Cotisations</th>
                            <th>Part patronale</th>
                            <th>Part salarié</th>
                            <th>Total</th>
                        </tr>

                        <tr>
                            <td>Retraite de base - tranche 1</td>
                            <td>{{ toEuro(computationResult.employer_contributions.basic_pension.contribution_t1) }}</td>
                            <td>{{ toEuro(computationResult.employee_contributions.basic_pension.contribution_t1) }}</td>
                            <td>{{ toEuro(computationResult.employer_contributions.basic_pension.contribution_t1 + computationResult.employee_contributions.basic_pension.contribution_t1) }}</td>
                        </tr>

                        <tr>
                            <td>Retraite de base - tranche 2</td>
                            <td>{{ toEuro(computationResult.employer_contributions.basic_pension.contribution_t2) }}</td>
                            <td>{{ toEuro(computationResult.employee_contributions.basic_pension.contribution_t2) }}</td>
                            <td>{{ toEuro(computationResult.employer_contributions.basic_pension.contribution_t2 + computationResult.employee_contributions.basic_pension.contribution_t2) }}</td>
                        </tr>

                        <tr>
                            <td>Retraite complémentaire - tranche 1</td>
                            <td>{{ toEuro(computationResult.employer_contributions.complementary_pension.contribution_t1) }}</td>
                            <td>{{ toEuro(computationResult.employee_contributions.complementary_pension.contribution_t1) }}</td>
                            <td>{{ toEuro(computationResult.employer_contributions.complementary_pension.contribution_t1 + computationResult.employee_contributions.complementary_pension.contribution_t1) }}</td>
                        </tr>

                        <tr>
                            <td>CEG - tranche 1</td>
                            <td>{{ toEuro(computationResult.employer_contributions.complementary_pension.ceg_t1) }}</td>
                            <td>{{ toEuro(computationResult.employee_contributions.complementary_pension.ceg_t1) }}</td>
                            <td>{{ toEuro(computationResult.employer_contributions.complementary_pension.ceg_t1 + computationResult.employee_contributions.complementary_pension.ceg_t1) }}</td>
                        </tr>

                        <tr>
                            <td>Retraite complémentaire - tranche 2</td>
                            <td>{{ toEuro(computationResult.employer_contributions.complementary_pension.contribution_t2) }}</td>
                            <td>{{ toEuro(computationResult.employee_contributions.complementary_pension.contribution_t2) }}</td>
                            <td>{{ toEuro(computationResult.employer_contributions.complementary_pension.contribution_t2 + computationResult.employee_contributions.complementary_pension.contribution_t2) }}</td>
                        </tr>

                        <tr>
                            <td>CEG - tranche 2</td>
                            <td>{{ toEuro(computationResult.employer_contributions.complementary_pension.ceg_t2) }}</td>
                            <td>{{ toEuro(computationResult.employee_contributions.complementary_pension.ceg_t2) }}</td>
                            <td>{{ toEuro(computationResult.employer_contributions.complementary_pension.ceg_t2 + computationResult.employee_contributions.complementary_pension.ceg_t2) }}</td>
                        </tr>

                        <tr>
                            <td>CET</td>
                            <td>{{ toEuro(computationResult.employer_contributions.complementary_pension.cet_contribution) }}</td>
                            <td>{{ toEuro(computationResult.employee_contributions.complementary_pension.cet_contribution) }}</td>
                            <td>{{ toEuro(computationResult.employer_contributions.complementary_pension.cet_contribution + computationResult.employee_contributions.complementary_pension.cet_contribution) }}</td>
                        </tr>

                        <tr>
                            <td>APEC</td>
                            <td>{{ toEuro(computationResult.employer_contributions.complementary_pension.apec_contribution) }}</td>
                            <td>{{ toEuro(computationResult.employee_contributions.complementary_pension.apec_contribution) }}</td>
                            <td>{{ toEuro(computationResult.employer_contributions.complementary_pension.apec_contribution + computationResult.employee_contributions.complementary_pension.apec_contribution) }}</td>
                        </tr>

                        <tr>
                            <td>Assurance maladie</td>
                            <td>{{ toEuro(computationResult.employer_contributions.seakness_insurance.total_contribution) }}</td>
                            <td>{{ toEuro(0) }}</td>
                            <td>{{ toEuro(computationResult.employer_contributions.seakness_insurance.total_contribution) }}</td>
                        </tr>

                        <tr>
                            <td>Allocations familiales</td>
                            <td>{{ toEuro(computationResult.employer_contributions.family_allowances.total_contribution) }}</td>
                            <td>{{ toEuro(0) }}</td>
                            <td>{{ toEuro(computationResult.employer_contributions.family_allowances.total_contribution) }}</td>
                        </tr>

                        <tr>
                            <td>Solidarité-autonomie (CSA)</td>
                            <td>{{ toEuro(computationResult.employer_contributions.autonomous_solidarity.total_contribution) }}</td>
                            <td>{{ toEuro(0) }}</td>
                            <td>{{ toEuro(computationResult.employer_contributions.autonomous_solidarity.total_contribution) }}</td>
                        </tr>

                        <tr>
                            <td>CSG</td>
                            <td>{{ toEuro(0) }}</td>
                            <td>{{ toEuro(computationResult.employee_contributions.csg_contribution.total_contribution) }}</td>
                            <td>{{ toEuro(computationResult.employee_contributions.csg_contribution.total_contribution) }}</td>
                        </tr>

                        <tr>
                            <td>CRDS</td>
                            <td>{{ toEuro(0) }}</td>
                            <td>{{ toEuro(computationResult.employee_contributions.crds_contribution.total_contribution) }}</td>
                            <td>{{ toEuro(computationResult.employee_contributions.crds_contribution.total_contribution) }}</td>
                        </tr>

                        <tr>
                            <td>CRDS</td>
                            <td>{{ toEuro(0) }}</td>
                            <td>{{ toEuro(computationResult.employee_contributions.crds_contribution.total_contribution) }}</td>
                            <td>{{ toEuro(computationResult.employee_contributions.crds_contribution.total_contribution) }}</td>
                        </tr>

                        <tr class="highlight">
                            <td>Total</td>
                            <td>{{ toEuro(computationResult.employer_contributions.total_contributions) }}</td>
                            <td>{{ toEuro(computationResult.employee_contributions.total_contributions) }}</td>
                            <td>{{ toEuro(computationResult.contributions) }}</td>
                        </tr>

                    </table>

                    <table v-else>
                        <tr class="highlight">
                            <th>Cotisations</th>
                            <th>Montant</th>
                        </tr>

                        <tr>
                            <td>Retraite de base - tranche 1</td>
                            <td>{{ toEuro(computationResult.basic_pension.contribution_t1) }}</td>
                        </tr>

                        <tr>
                            <td>Retraite de base - tranche 2</td>
                            <td>{{ toEuro(computationResult.basic_pension.contribution_t2) }}</td>
                        </tr>

                        <tr v-if="job_status != 'doctor'">
                            <td>Retraite complémentaire - tranche 1</td>
                            <td>{{ toEuro(computationResult.complementary_pension.contribution_t1) }}</td>
                        </tr>

                        <tr v-if="job_status != 'doctor'">
                            <td>Retraite complémentaire - tranche 2</td>
                            <td>{{ toEuro(computationResult.complementary_pension.contribution_t2) }}</td>
                        </tr>

                        <tr v-if="job_status == 'doctor'">
                            <td>Retraite complémentaire (CARMF)</td>
                            <td>{{ toEuro(computationResult.complementary_pension.total_contribution) }}</td>
                        </tr>

                        <tr v-if="job_status == 'doctor'">
                            <td>Retraite complémentaire (ASV)</td>
                            <td>{{ toEuro(computationResult.asv_pension.total_contribution) }}</td>
                        </tr>

                        <tr>
                            <td>Indémnités journalières</td>
                            <td>{{ toEuro(computationResult.daily_allowances.total_contribution) }}</td>
                        </tr>

                        <tr>
                            <td>Allocations familiales</td>
                            <td>{{ toEuro(computationResult.family_allowances.total_contribution) }}</td>
                        </tr>

                        <tr>
                            <td>Allocations familiales - cotisation de base</td>
                            <td>{{ toEuro(computationResult.illness_and_maternity.base_contribution) }}</td>
                        </tr>

                        <tr>
                            <td>Allocations familiales - contribution complémentaire</td>
                            <td>{{ toEuro(computationResult.illness_and_maternity.complementary_contribution) }}</td>
                        </tr>

                        <tr>
                            <td>Invalidité / décès</td>
                            <td>{{ toEuro(computationResult.disability_and_death.total_contribution) }}</td>
                        </tr>

                        <tr>
                            <td>Formation professionnelle</td>
                            <td>{{ toEuro(computationResult.cfp_contribution.total_contribution) }}</td>
                        </tr>

                        <tr v-if="job_status == 'doctor'">
                            <td>Union régionale des Professionnels de Santé (CURPS)</td>
                            <td>{{ toEuro(computationResult.curps_contribution.total_contribution) }}</td>
                        </tr>

                        <tr>
                            <td>CSG</td>
                            <td>{{ toEuro(computationResult.csg_contribution.total_contribution) }}</td>
                        </tr>

                        <tr>
                            <td>CRDS</td>
                            <td>{{ toEuro(computationResult.crds_contribution.total_contribution) }}</td>
                        </tr>

                        <tr class="highlight">
                            <td>TOTAL</td>
                            <td>{{ toEuro(computationResult.contributions) }}</td>
                        </tr>
                    </table>
                </div>
            </div>

            <div class="right-section">
                <div class="section-element" v-if="!computationResult">
                    <h3>Résultats</h3>

                    <img src="../ressources/img/illustration.png"/>

                    <p>Lancez le calcul pour obtenir un résultat</p>
                </div>

                <div class="section-element" v-if="computationResult">
                    <h3>Montant des cotisations</h3>
                    <h4>{{ toEuro(computationResult.contributions) }}</h4>
                </div>
            </div>
        </div>
    </div> 
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            show_detailed_result: false,
            job_status: "employee",
            gross_income: 100000,
            computationResult: undefined,
        }
    },
    computed: {
        
    },
    methods: {
        async computeResult() {

            const res = await axios.post(this.$store.getters.get_api_url + 'simulators/social-contributions', {
                job_status: this.job_status, 
                gross_income: this.gross_income,
            }, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })

            this.computationResult = res.data
        }
    },
}
</script>

<style src="./style.css" scoped></style>