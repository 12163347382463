<template>
    <div>
        <h1 class="section-label">Simulations rémunération du dirigeant</h1>
        
        <table class="simulation-table">
            <tr>
                <th>Nom du scenario</th>
                <th>Budget de rémunération</th>
                <th>Détail</th>
                <th>Comparaison</th>
            </tr>

            <tr class="social-charge-simulation" v-for="(simulation, index) in simulationsList" :key="simulation._id">
                <td>{{ simulation.label }}</td>
                <td>{{ toEuro(simulation.budget) }}</td>

                <td @click="toggleForm(simulation._id)"> 
                    <edit-icon style="cursor: pointer"/>
                    <modale :show="showForm == simulation._id" @toggle="toggleForm(simulation._id)">
                        <SocialChargesResult  :simulationObj="simulation" @modification="getAllSimulations" @toggle="toggleForm(simulation._id)"/>
                    </modale>
                </td>

                <td :rowspan="1 + simulationsList.length" v-if="index == 0">
                    voir
                </td>
            </tr>

            <tr>
                <td class="btn-new-simulation" colspan="3" @click="toggleNew">
                    Nouvelle simulation
                    <modale :show="showNew" @toggle="toggleNew">
                        <SocialChargesResult :selectedScenario="selectedScenario" @modification="getAllSimulations" @toggle="toggleNew"/>
                    </modale>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
import axios from "axios"
import SocialChargesResult from '../../../../results/socialChargesResult.vue'

export default {
    props: ['selectedScenario'],
    components: {
        SocialChargesResult
    },
    data() {
        return {
            simulationsList: [],
            showForm: undefined,
            showNew: false,
        }
    },
    watch: {
        selectedScenario() {
            this.getAllSimulations()
        }
    },
    methods: {
        toggleForm(id) {
            if (this.showForm == id) {
                this.showForm = undefined
            } else {
                this.showForm = id
            }
        },
        toggleNew() {
            this.showNew = !this.showNew
        },
        async getAllSimulations() {
            const res = await axios.get(this.$store.getters.get_api_url + 'clients/manager-remuneration/' + this.selectedScenario, {
                headers: { authorization : 'Bearer ' + this.$store.getters.get_token}
            })
            this.simulationsList = res.data
        }
    },
    mounted() {
        this.getAllSimulations()
    }
}
</script>

<style src="./style.css" scoped></style>

<style scoped>

.btn-new-simulation {
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
}

.btn-new-simulation:hover {
    color: rgb(245, 160, 4);
}
</style>